/*!-----------------------------------------------------------------
    Name: Mimilism - Minimal and Modern Portfolio HTML Template
    Version: 1.0.0
    Author: dexad
    Website: https://dkcoder.info/
    Purchase: https://themeforest.net/user/unvab/portfolio
    Support: https://dkcoder.info/
    License: You must have a valid license purchased only from ThemeForest (the above link) in order to legally use the theme for your project.
    Copyright 2018.
-------------------------------------------------------------------*/
    /*------------------------------------------------------------------
  [Typography]

  Body:     16px/1.5 Lato, sans-serif;
  Headings: Lato, sans-serif

  Note: Most of font sizes are relative to the base font size (rem)
 -------------------------------------------------------------------*/
/*------------------------------------------------------------------
  [Color codes]

  Main 1:    #1c334d
  Main 2:    #f03f3b
  Main 3:    #c9a57d

  Dark 1:    #131313
  Dark 2:    #202020
 -------------------------------------------------------------------*/
/*------------------------------------------------------------------
  [Table of contents]
  
  1. Base
  2. Typography
  3. Bootstrap
  4. Helpers
  5. Navbar
  6. Navbar Fullscreen
  7. Header
  8. Portfolio
  9. Reviews
  10. Numbers
  11. Footer
  12. Widgets
  13. Social Links
  14. Elements
    - Element Buttons
    - Element Feature
    - Element Content Boxes
    - Element Pagination
    - Element Blockquotes
    - Element Image Boxes
    - Element Forms
    - Element Icons
  15. Blog
  16. Plugins
    - Plugin Swiper
    - Plugin Isotope
 -------------------------------------------------------------------*/
/*------------------------------------------------------------------

  Base

 -------------------------------------------------------------------*/
body {
    position: relative;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: #fff;
}

@media screen and (min-width: 768px) {
    body::-webkit-scrollbar-track {
        background-color: #fff;
    }
    body::-webkit-scrollbar {
        width: 10px;
        background-color: #fff;
    }
    body::-webkit-scrollbar-thumb {
        background-color: #202020;
    }
    body.dk-open-fullscreen {
        padding-right: 10px;
    }
    body.dk-open-fullscreen .dk-navbar-fixed {
        right: 10px;
    }
}

body.dk-open-fullscreen {
    overflow: hidden;
}

.dk-main {
    overflow: hidden;
}

.dk-img {
    position: relative;
    display: block;
    max-width: 100%;
    overflow: hidden;
}

.dk-img > img {
    max-width: 100%;
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    -o-transition: transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}

.dk-img::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    background-color: rgba(32, 32, 32, 0.1);
    opacity: 0;
    -webkit-transition: visibility .3s ease, opacity .3s ease;
    -o-transition: visibility .3s ease, opacity .3s ease;
    transition: visibility .3s ease, opacity .3s ease;
}

.dk-img:hover > img {
    -webkit-transform: scale(1.03);
        -ms-transform: scale(1.03);
            transform: scale(1.03);
}

.dk-img:hover::after {
    visibility: visible;
    opacity: 1;
}

.link-decorated {
    display: inline-block;
    font-size: .9rem;
    font-weight: 500;
    color: #131313;
    text-decoration: none;
    text-transform: uppercase;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    -webkit-transition: border-color .2s ease;
    -o-transition: border-color .2s ease;
    transition: border-color .2s ease;
}

.link-decorated:hover, .link-decorated:focus {
    color: #131313;
    text-decoration: none;
    border-color: #131313;
}

.link-decorated.link-decorated-white {
    color: #fff;
}

.link-decorated.link-decorated-white:hover, .link-decorated.link-decorated-white:focus {
    color: #fff;
    border-color: #fff;
}

.bg-image-parallax,
.bg-image {
    overflow: hidden;
}

.bg-image-parallax img,
.bg-image img {
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover";
    -o-object-fit: cover;
       object-fit: cover;
}

.icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.icon-quote {
    width: 41px;
    height: 33px;
    background-image: url("../images/icon-quote.svg");
}

a {
    color: #2db3cb;
    -webkit-transition: .15s color;
    -o-transition: .15s color;
    transition: .15s color;
}

a:focus,
a:hover {
    color: rgba(150, 150, 150, 0.5) !important;
}

.dk-progress {
    display: block;
    width: 100%;
}

.dk-progress + .dk-progress {
    margin-top: 35px;
}

.dk-progress .dk-progress-title {
    font-size: .84rem;
    font-weight: 500;
    color: #131313;
    text-transform: uppercase;
}

.dk-progress .dk-progress-title > span {
    display: inline-block;
    margin-left: 20px;
}

.dk-progress > .dk-progress {
    position: relative;
    height: 3px;
    margin-top: 14px;
    background-color: #eaeaea;
}

.dk-progress > .dk-progress > span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.2);
}

.dk-list {
    padding: 0;
    margin: 0;
}

.dk-list > li {
    padding: 0;
    margin: 0;
    list-style: none;
}

.dk-list > li + li {
    margin-top: 8px;
}

.dk-list > li strong {
    color: #131313;
}

.dk-list > li a {
    color: #5e5e5e;
    text-decoration: none;
}

.dk-list > li a:hover, .dk-list > li a:focus {
    color: #131313;
    text-decoration: none;
}

.bg-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 50% 50%;
    background-size: cover;
    z-index: -1;
}

.bg-image > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 50% 50%;
    background-size: cover;
}

.bg-image.bg-pattern {
    background-size: auto;
}

.bg-image.bg-pattern > * {
    background-size: auto;
}

.dk-gap,
.dk-gap-1,
.dk-gap-2,
.dk-gap-3,
.dk-gap-4,
.dk-gap-5,
.dk-gap-6 {
    display: block;
    height: 15px;
}

.dk-gap::after, .dk-gap::before,
.dk-gap-1::after,
.dk-gap-1::before,
.dk-gap-2::after,
.dk-gap-2::before,
.dk-gap-3::after,
.dk-gap-3::before,
.dk-gap-4::after,
.dk-gap-4::before,
.dk-gap-5::after,
.dk-gap-5::before,
.dk-gap-6::after,
.dk-gap-6::before {
    content: "";
    display: table;
    clear: both;
}

.dk-gap-1 {
    height: 25px;
}

.dk-gap-2 {
    height: 40px;
}

.dk-gap-3 {
    height: 50px;
}

.dk-gap-4 {
    height: 60px;
}

.dk-gap-5 {
    height: 80px;
}

.dk-gap-6 {
    height: 100px;
}

.dk-body-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

.dk-gmaps {
    width: 100%;
    height: 300px;
}

.dk-gmaps-sm {
    height: 250px;
}

.dk-gmaps-md {
    height: 450px;
}

.dk-gmaps-lg {
    height: 570px;
}

.dk-gmaps-full {
    height: 700px;
    height: 100vh;
    min-height: 700px;
}

/*------------------------------------------------------------------

  Typography

 -------------------------------------------------------------------*/
html {
    font-size: 16px;
}

body {
    font-family: "Lato", sans-serif;
    line-height: 1.5;
    color: #5e5e5e;
    letter-spacing: .03em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin-bottom: 1.5rem;
}

b,
strong {
    font-weight: 500;
}

label {
    font-weight: 500;
    color: #1c334d;
}

/* headings */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 1.5rem;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    color: #131313;
    text-transform: none;
    letter-spacing: normal;
}

h1,
.h1 {
    font-size: 2.5rem;
}

h2,
.h2 {
    font-size: 2.16rem;
}

h3,
.h3 {
    font-size: 1.74rem;
}

h4,
.h4 {
    font-size: 1.4rem;
}

h5,
.h5 {
    font-size: 1.27rem;
}

h6,
.h6 {
    font-size: .88rem;
}

.display-1,
.display-2,
.display-3,
.display-4 {
    font-weight: 500;
    text-transform: none;
    letter-spacing: normal;
}

.display-1 {
    font-size: 4.2rem;
}

.display-2 {
    font-size: 3.75rem;
}

.display-3 {
    font-size: 3rem;
    line-height: 1.15;
}

.display-4 {
    font-size: 3rem;
}

/* Lead */
.lead {
    font-size: 1.14rem;
    font-weight: 400;
}

/*------------------------------------------------------------------

  Bootstrap

 -------------------------------------------------------------------*/
@media (min-width: 1200px) {
    .container {
        max-width: 1210px;
    }
}

@media screen and (max-width: 992px) {
    .no-gutters-lg {
        margin-right: 0;
        margin-left: 0;
    }
    .no-gutters-lg > .col,
    .no-gutters-lg > [class*="col"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.container {
    padding-right: 20px;
    padding-left: 20px;
}

.row.vertical-gap > [class*="col"] {
    padding-top: 30px;
}

.row.vertical-gap {
    margin-top: -30px;
}

.row.only-vertical-gap > [class*="col"] {
    padding: 30px 0 0;
}

.row.only-vertical-gap {
    margin: -30px 0 0;
}

.row.xs-gap {
    margin-right: -5px;
    margin-left: -5px;
}

.row.xs-gap > [class*="col"] {
    padding-right: 5px;
    padding-left: 5px;
}

.row.xs-gap.vertical-gap > [class*="col"] {
    padding-top: 10px;
}

.row.xs-gap.vertical-gap {
    margin-top: -10px;
}

.row.sm-gap {
    margin-right: -10px;
    margin-left: -10px;
}

.row.sm-gap > [class*="col"] {
    padding-right: 10px;
    padding-left: 10px;
}

.row.sm-gap.vertical-gap > [class*="col"] {
    padding-top: 20px;
}

.row.sm-gap.vertical-gap {
    margin-top: -20px;
}

.row.md-gap {
    margin-right: -22.5px;
    margin-left: -22.5px;
}

.row.md-gap > [class*="col"] {
    padding-right: 22.5px;
    padding-left: 22.5px;
}

.row.md-gap.vertical-gap > [class*="col"] {
    padding-top: 45px;
}

.row.md-gap.vertical-gap {
    margin-top: -45px;
}

.row.lg-gap {
    margin-right: -30px;
    margin-left: -30px;
}

.row.lg-gap > [class*="col"] {
    padding-right: 30px;
    padding-left: 30px;
}

.row.lg-gap.vertical-gap > [class*="col"] {
    padding-top: 65px;
}

.row.lg-gap.vertical-gap {
    margin-top: -65px;
}

/*------------------------------------------------------------------

  Helpers

 -------------------------------------------------------------------*/
/*
 * Text Color Helper
 */
.text-main-1 {
    color: rgba(255, 255, 255, 0.2) !important;
}

.text-main-2 {
    color: #f03f3b !important;
}

.text-main-3 {
    color: #c9a57d !important;
}

.text-white {
    color: #fff !important;
}

.text-black {
    color: #000 !important;
}

.text-1 {
    color: #5e5e5e !important;
}

.text-2 {
    color: #a8b1bd !important;
}

.text-3 {
    color: #7a7a7a !important;
}

.text-4 {
    color: #d5d5d5 !important;
}

.text-grey-1 {
    color: #858585 !important;
}

.text-grey-2 {
    color: #c8c8c8 !important;
}

.text-grey-3 {
    color: #7a7a7a !important;
}

.text-grey-4 {
    color: #eaeaea !important;
}

.text-grey-5 {
    color: #f3f3f3 !important;
}

.text-dark-1 {
    color: #131313 !important;
}

.text-dark-2 {
    color: #202020 !important;
}

.text-dark-3 {
    color: #171717 !important;
}

.text-dark-4 {
    color: #1c334d !important;
}

.text-dark-5 {
    color: #142232 !important;
}

/*
 * Bg Color Helper
 */
.bg-main-1 {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.bg-main-2 {
    background-color: #f03f3b !important;
}

.bg-main-3 {
    background-color: #c9a57d !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-black {
    background-color: #000 !important;
}

.bg-1 {
    background-color: #5e5e5e !important;
}

.bg-2 {
    background-color: #a8b1bd !important;
}

.bg-3 {
    background-color: #7a7a7a !important;
}

.bg-4 {
    background-color: #d5d5d5 !important;
}

.bg-grey-1 {
    background-color: #858585 !important;
}

.bg-grey-2 {
    background-color: #c8c8c8 !important;
}

.bg-grey-3 {
    background-color: #7a7a7a !important;
}

.bg-grey-4 {
    background-color: #eaeaea !important;
}

.bg-grey-5 {
    background-color: #f3f3f3 !important;
}

.bg-dark-1 {
    background-color: #131313 !important;
}

.bg-dark-2 {
    background-color: #202020 !important;
}

.bg-dark-3 {
    background-color: #171717 !important;
}

.bg-dark-4 {
    background-color: #1c334d !important;
}

.bg-dark-5 {
    background-color: #142232 !important;
}

/*
 * Image Fit
 */
.dk-img {
    max-width: 100%;
    height: auto;
}

.dk-img img {
    max-width: 100%;
    height: auto;
}

/*
 * Text Align Helper
 */
.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

/*
 * Inline Block Helper
 */
.dib {
    display: inline-block !important;
}

/*
 * Block Helper
 */
.db {
    display: block;
    width: 100%;
}

/*
 * Text Transform Helper
 */
.text-uppercase {
    text-transform: uppercase !important;
}

/*
 * Font Weight Helper
 */
.fw-100 {
    font-weight: 100 !important;
}

.fw-200 {
    font-weight: 200 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-900 {
    font-weight: 900 !important;
}

/*
 * Font Size Helper
 */
.fs-4 {
    font-size: 4px !important;
}

.fs-5 {
    font-size: 5px !important;
}

.fs-6 {
    font-size: 6px !important;
}

.fs-7 {
    font-size: 7px !important;
}

.fs-8 {
    font-size: 8px !important;
}

.fs-9 {
    font-size: 9px !important;
}

.fs-10 {
    font-size: 10px !important;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-19 {
    font-size: 19px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-26 {
    font-size: 26px !important;
}

.fs-28 {
    font-size: 28px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.fs-35 {
    font-size: 35px !important;
}

.fs-40 {
    font-size: 40px !important;
}

.fs-45 {
    font-size: 45px !important;
}

.fs-50 {
    font-size: 50px !important;
}

/*
 * Line Height Helper
 */
.lh-0 {
    line-height: 0px !important;
}

.lh-5 {
    line-height: 5px !important;
}

.lh-10 {
    line-height: 10px !important;
}

.lh-15 {
    line-height: 15px !important;
}

.lh-20 {
    line-height: 20px !important;
}

.lh-25 {
    line-height: 25px !important;
}

.lh-30 {
    line-height: 30px !important;
}

.lh-35 {
    line-height: 35px !important;
}

.lh-40 {
    line-height: 40px !important;
}

.lh-45 {
    line-height: 45px !important;
}

.lh-50 {
    line-height: 50px !important;
}

/*
 * Border Radius Helper
 */
.br-0 {
    border-radius: 0px !important;
}

.br-1 {
    border-radius: 1px !important;
}

.br-2 {
    border-radius: 2px !important;
}

.br-3 {
    border-radius: 3px !important;
}

.br-4 {
    border-radius: 4px !important;
}

.br-5 {
    border-radius: 5px !important;
}

.br-6 {
    border-radius: 6px !important;
}

.br-7 {
    border-radius: 7px !important;
}

.br-8 {
    border-radius: 8px !important;
}

.br-9 {
    border-radius: 9px !important;
}

.br-10 {
    border-radius: 10px !important;
}

.br-12 {
    border-radius: 12px !important;
}

.br-14 {
    border-radius: 14px !important;
}

.br-16 {
    border-radius: 16px !important;
}

.br-18 {
    border-radius: 18px !important;
}

.br-20 {
    border-radius: 20px !important;
}

.br-25 {
    border-radius: 25px !important;
}

.br-30 {
    border-radius: 30px !important;
}

.br-35 {
    border-radius: 35px !important;
}

/*
 * Paddings Helper
 */
.p-0 {
    padding: 0px !important;
}

.p-1 {
    padding: 1px !important;
}

.p-2 {
    padding: 2px !important;
}

.p-3 {
    padding: 3px !important;
}

.p-4 {
    padding: 4px !important;
}

.p-5 {
    padding: 5px !important;
}

.p-6 {
    padding: 6px !important;
}

.p-7 {
    padding: 7px !important;
}

.p-8 {
    padding: 8px !important;
}

.p-9 {
    padding: 9px !important;
}

.p-10 {
    padding: 10px !important;
}

.p-15 {
    padding: 15px !important;
}

.p-20 {
    padding: 20px !important;
}

.p-25 {
    padding: 25px !important;
}

.p-30 {
    padding: 30px !important;
}

.p-35 {
    padding: 35px !important;
}

.p-40 {
    padding: 40px !important;
}

.p-45 {
    padding: 45px !important;
}

.p-50 {
    padding: 50px !important;
}

.p-60 {
    padding: 60px !important;
}

.p-70 {
    padding: 70px !important;
}

.p-80 {
    padding: 80px !important;
}

.p-85 {
    padding: 85px !important;
}

.p-90 {
    padding: 90px !important;
}

.p-100 {
    padding: 100px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pt-1 {
    padding-top: 1px !important;
}

.pt-2 {
    padding-top: 2px !important;
}

.pt-3 {
    padding-top: 3px !important;
}

.pt-4 {
    padding-top: 4px !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-6 {
    padding-top: 6px !important;
}

.pt-7 {
    padding-top: 7px !important;
}

.pt-8 {
    padding-top: 8px !important;
}

.pt-9 {
    padding-top: 9px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-25 {
    padding-top: 25px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pt-35 {
    padding-top: 35px !important;
}

.pt-40 {
    padding-top: 40px !important;
}

.pt-45 {
    padding-top: 45px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pt-60 {
    padding-top: 60px !important;
}

.pt-70 {
    padding-top: 70px !important;
}

.pt-80 {
    padding-top: 80px !important;
}

.pt-85 {
    padding-top: 85px !important;
}

.pt-90 {
    padding-top: 90px !important;
}

.pt-100 {
    padding-top: 100px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pl-1 {
    padding-left: 1px !important;
}

.pl-2 {
    padding-left: 2px !important;
}

.pl-3 {
    padding-left: 3px !important;
}

.pl-4 {
    padding-left: 4px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.pl-6 {
    padding-left: 6px !important;
}

.pl-7 {
    padding-left: 7px !important;
}

.pl-8 {
    padding-left: 8px !important;
}

.pl-9 {
    padding-left: 9px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.pl-20 {
    padding-left: 20px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.pl-30 {
    padding-left: 30px !important;
}

.pl-35 {
    padding-left: 35px !important;
}

.pl-40 {
    padding-left: 40px !important;
}

.pl-45 {
    padding-left: 45px !important;
}

.pl-50 {
    padding-left: 50px !important;
}

.pl-60 {
    padding-left: 60px !important;
}

.pl-70 {
    padding-left: 70px !important;
}

.pl-80 {
    padding-left: 80px !important;
}

.pl-85 {
    padding-left: 85px !important;
}

.pl-90 {
    padding-left: 90px !important;
}

.pl-100 {
    padding-left: 100px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.pr-1 {
    padding-right: 1px !important;
}

.pr-2 {
    padding-right: 2px !important;
}

.pr-3 {
    padding-right: 3px !important;
}

.pr-4 {
    padding-right: 4px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pr-6 {
    padding-right: 6px !important;
}

.pr-7 {
    padding-right: 7px !important;
}

.pr-8 {
    padding-right: 8px !important;
}

.pr-9 {
    padding-right: 9px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.pr-25 {
    padding-right: 25px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.pr-35 {
    padding-right: 35px !important;
}

.pr-40 {
    padding-right: 40px !important;
}

.pr-45 {
    padding-right: 45px !important;
}

.pr-50 {
    padding-right: 50px !important;
}

.pr-60 {
    padding-right: 60px !important;
}

.pr-70 {
    padding-right: 70px !important;
}

.pr-80 {
    padding-right: 80px !important;
}

.pr-85 {
    padding-right: 85px !important;
}

.pr-90 {
    padding-right: 90px !important;
}

.pr-100 {
    padding-right: 100px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pb-1 {
    padding-bottom: 1px !important;
}

.pb-2 {
    padding-bottom: 2px !important;
}

.pb-3 {
    padding-bottom: 3px !important;
}

.pb-4 {
    padding-bottom: 4px !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pb-6 {
    padding-bottom: 6px !important;
}

.pb-7 {
    padding-bottom: 7px !important;
}

.pb-8 {
    padding-bottom: 8px !important;
}

.pb-9 {
    padding-bottom: 9px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-25 {
    padding-bottom: 25px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-35 {
    padding-bottom: 35px !important;
}

.pb-40 {
    padding-bottom: 40px !important;
}

.pb-45 {
    padding-bottom: 45px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pb-60 {
    padding-bottom: 60px !important;
}

.pb-70 {
    padding-bottom: 70px !important;
}

.pb-80 {
    padding-bottom: 80px !important;
}

.pb-85 {
    padding-bottom: 85px !important;
}

.pb-90 {
    padding-bottom: 90px !important;
}

.pb-100 {
    padding-bottom: 100px !important;
}

/*
 * Margins Helper
 */
.mauto {
    margin-right: auto;
    margin-left: auto;
}

.m-0 {
    margin: 0px !important;
}

.m-1 {
    margin: 1px !important;
}

.m-2 {
    margin: 2px !important;
}

.m-3 {
    margin: 3px !important;
}

.m-4 {
    margin: 4px !important;
}

.m-5 {
    margin: 5px !important;
}

.m-6 {
    margin: 6px !important;
}

.m-7 {
    margin: 7px !important;
}

.m-8 {
    margin: 8px !important;
}

.m-9 {
    margin: 9px !important;
}

.m-10 {
    margin: 10px !important;
}

.m-13 {
    margin: 13px !important;
}

.m-15 {
    margin: 15px !important;
}

.m-18 {
    margin: 18px !important;
}

.m-20 {
    margin: 20px !important;
}

.m-25 {
    margin: 25px !important;
}

.m-30 {
    margin: 30px !important;
}

.m-35 {
    margin: 35px !important;
}

.m-40 {
    margin: 40px !important;
}

.m-45 {
    margin: 45px !important;
}

.m-50 {
    margin: 50px !important;
}

.m-55 {
    margin: 55px !important;
}

.m-60 {
    margin: 60px !important;
}

.m-70 {
    margin: 70px !important;
}

.m-80 {
    margin: 80px !important;
}

.m-90 {
    margin: 90px !important;
}

.m-100 {
    margin: 100px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mt-1 {
    margin-top: 1px !important;
}

.mt-2 {
    margin-top: 2px !important;
}

.mt-3 {
    margin-top: 3px !important;
}

.mt-4 {
    margin-top: 4px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-6 {
    margin-top: 6px !important;
}

.mt-7 {
    margin-top: 7px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-9 {
    margin-top: 9px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-13 {
    margin-top: 13px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-18 {
    margin-top: 18px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-35 {
    margin-top: 35px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mt-45 {
    margin-top: 45px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-55 {
    margin-top: 55px !important;
}

.mt-60 {
    margin-top: 60px !important;
}

.mt-70 {
    margin-top: 70px !important;
}

.mt-80 {
    margin-top: 80px !important;
}

.mt-90 {
    margin-top: 90px !important;
}

.mt-100 {
    margin-top: 100px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.ml-1 {
    margin-left: 1px !important;
}

.ml-2 {
    margin-left: 2px !important;
}

.ml-3 {
    margin-left: 3px !important;
}

.ml-4 {
    margin-left: 4px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-6 {
    margin-left: 6px !important;
}

.ml-7 {
    margin-left: 7px !important;
}

.ml-8 {
    margin-left: 8px !important;
}

.ml-9 {
    margin-left: 9px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-13 {
    margin-left: 13px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-18 {
    margin-left: 18px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-25 {
    margin-left: 25px !important;
}

.ml-30 {
    margin-left: 30px !important;
}

.ml-35 {
    margin-left: 35px !important;
}

.ml-40 {
    margin-left: 40px !important;
}

.ml-45 {
    margin-left: 45px !important;
}

.ml-50 {
    margin-left: 50px !important;
}

.ml-55 {
    margin-left: 55px !important;
}

.ml-60 {
    margin-left: 60px !important;
}

.ml-70 {
    margin-left: 70px !important;
}

.ml-80 {
    margin-left: 80px !important;
}

.ml-90 {
    margin-left: 90px !important;
}

.ml-100 {
    margin-left: 100px !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.mr-1 {
    margin-right: 1px !important;
}

.mr-2 {
    margin-right: 2px !important;
}

.mr-3 {
    margin-right: 3px !important;
}

.mr-4 {
    margin-right: 4px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-6 {
    margin-right: 6px !important;
}

.mr-7 {
    margin-right: 7px !important;
}

.mr-8 {
    margin-right: 8px !important;
}

.mr-9 {
    margin-right: 9px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-13 {
    margin-right: 13px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-18 {
    margin-right: 18px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-25 {
    margin-right: 25px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mr-35 {
    margin-right: 35px !important;
}

.mr-40 {
    margin-right: 40px !important;
}

.mr-45 {
    margin-right: 45px !important;
}

.mr-50 {
    margin-right: 50px !important;
}

.mr-55 {
    margin-right: 55px !important;
}

.mr-60 {
    margin-right: 60px !important;
}

.mr-70 {
    margin-right: 70px !important;
}

.mr-80 {
    margin-right: 80px !important;
}

.mr-90 {
    margin-right: 90px !important;
}

.mr-100 {
    margin-right: 100px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-1 {
    margin-bottom: 1px !important;
}

.mb-2 {
    margin-bottom: 2px !important;
}

.mb-3 {
    margin-bottom: 3px !important;
}

.mb-4 {
    margin-bottom: 4px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-6 {
    margin-bottom: 6px !important;
}

.mb-7 {
    margin-bottom: 7px !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}

.mb-9 {
    margin-bottom: 9px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-13 {
    margin-bottom: 13px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-18 {
    margin-bottom: 18px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-35 {
    margin-bottom: 35px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-45 {
    margin-bottom: 45px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-55 {
    margin-bottom: 55px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

.mb-80 {
    margin-bottom: 80px !important;
}

.mb-90 {
    margin-bottom: 90px !important;
}

.mb-100 {
    margin-bottom: 100px !important;
}

/*
 * Negative Margins Helper
 */
.mn-0 {
    margin: 0px !important;
}

.mn-1 {
    margin: -1px !important;
}

.mn-2 {
    margin: -2px !important;
}

.mn-3 {
    margin: -3px !important;
}

.mn-4 {
    margin: -4px !important;
}

.mn-5 {
    margin: -5px !important;
}

.mn-6 {
    margin: -6px !important;
}

.mn-7 {
    margin: -7px !important;
}

.mn-8 {
    margin: -8px !important;
}

.mn-9 {
    margin: -9px !important;
}

.mn-10 {
    margin: -10px !important;
}

.mn-13 {
    margin: -13px !important;
}

.mn-15 {
    margin: -15px !important;
}

.mn-18 {
    margin: -18px !important;
}

.mn-20 {
    margin: -20px !important;
}

.mn-25 {
    margin: -25px !important;
}

.mn-30 {
    margin: -30px !important;
}

.mn-35 {
    margin: -35px !important;
}

.mn-40 {
    margin: -40px !important;
}

.mn-45 {
    margin: -45px !important;
}

.mn-50 {
    margin: -50px !important;
}

.mn-55 {
    margin: -55px !important;
}

.mn-60 {
    margin: -60px !important;
}

.mn-70 {
    margin: -70px !important;
}

.mn-80 {
    margin: -80px !important;
}

.mn-90 {
    margin: -90px !important;
}

.mn-100 {
    margin: -100px !important;
}

.mnt-0 {
    margin-top: 0px !important;
}

.mnt-1 {
    margin-top: -1px !important;
}

.mnt-2 {
    margin-top: -2px !important;
}

.mnt-3 {
    margin-top: -3px !important;
}

.mnt-4 {
    margin-top: -4px !important;
}

.mnt-5 {
    margin-top: -5px !important;
}

.mnt-6 {
    margin-top: -6px !important;
}

.mnt-7 {
    margin-top: -7px !important;
}

.mnt-8 {
    margin-top: -8px !important;
}

.mnt-9 {
    margin-top: -9px !important;
}

.mnt-10 {
    margin-top: -10px !important;
}

.mnt-13 {
    margin-top: -13px !important;
}

.mnt-15 {
    margin-top: -15px !important;
}

.mnt-18 {
    margin-top: -18px !important;
}

.mnt-20 {
    margin-top: -20px !important;
}

.mnt-25 {
    margin-top: -25px !important;
}

.mnt-30 {
    margin-top: -30px !important;
}

.mnt-35 {
    margin-top: -35px !important;
}

.mnt-40 {
    margin-top: -40px !important;
}

.mnt-45 {
    margin-top: -45px !important;
}

.mnt-50 {
    margin-top: -50px !important;
}

.mnt-55 {
    margin-top: -55px !important;
}

.mnt-60 {
    margin-top: -60px !important;
}

.mnt-70 {
    margin-top: -70px !important;
}

.mnt-80 {
    margin-top: -80px !important;
}

.mnt-90 {
    margin-top: -90px !important;
}

.mnt-100 {
    margin-top: -100px !important;
}

.mnl-0 {
    margin-left: 0px !important;
}

.mnl-1 {
    margin-left: -1px !important;
}

.mnl-2 {
    margin-left: -2px !important;
}

.mnl-3 {
    margin-left: -3px !important;
}

.mnl-4 {
    margin-left: -4px !important;
}

.mnl-5 {
    margin-left: -5px !important;
}

.mnl-6 {
    margin-left: -6px !important;
}

.mnl-7 {
    margin-left: -7px !important;
}

.mnl-8 {
    margin-left: -8px !important;
}

.mnl-9 {
    margin-left: -9px !important;
}

.mnl-10 {
    margin-left: -10px !important;
}

.mnl-13 {
    margin-left: -13px !important;
}

.mnl-15 {
    margin-left: -15px !important;
}

.mnl-18 {
    margin-left: -18px !important;
}

.mnl-20 {
    margin-left: -20px !important;
}

.mnl-25 {
    margin-left: -25px !important;
}

.mnl-30 {
    margin-left: -30px !important;
}

.mnl-35 {
    margin-left: -35px !important;
}

.mnl-40 {
    margin-left: -40px !important;
}

.mnl-45 {
    margin-left: -45px !important;
}

.mnl-50 {
    margin-left: -50px !important;
}

.mnl-55 {
    margin-left: -55px !important;
}

.mnl-60 {
    margin-left: -60px !important;
}

.mnl-70 {
    margin-left: -70px !important;
}

.mnl-80 {
    margin-left: -80px !important;
}

.mnl-90 {
    margin-left: -90px !important;
}

.mnl-100 {
    margin-left: -100px !important;
}

.mnr-0 {
    margin-right: 0px !important;
}

.mnr-1 {
    margin-right: -1px !important;
}

.mnr-2 {
    margin-right: -2px !important;
}

.mnr-3 {
    margin-right: -3px !important;
}

.mnr-4 {
    margin-right: -4px !important;
}

.mnr-5 {
    margin-right: -5px !important;
}

.mnr-6 {
    margin-right: -6px !important;
}

.mnr-7 {
    margin-right: -7px !important;
}

.mnr-8 {
    margin-right: -8px !important;
}

.mnr-9 {
    margin-right: -9px !important;
}

.mnr-10 {
    margin-right: -10px !important;
}

.mnr-13 {
    margin-right: -13px !important;
}

.mnr-15 {
    margin-right: -15px !important;
}

.mnr-18 {
    margin-right: -18px !important;
}

.mnr-20 {
    margin-right: -20px !important;
}

.mnr-25 {
    margin-right: -25px !important;
}

.mnr-30 {
    margin-right: -30px !important;
}

.mnr-35 {
    margin-right: -35px !important;
}

.mnr-40 {
    margin-right: -40px !important;
}

.mnr-45 {
    margin-right: -45px !important;
}

.mnr-50 {
    margin-right: -50px !important;
}

.mnr-55 {
    margin-right: -55px !important;
}

.mnr-60 {
    margin-right: -60px !important;
}

.mnr-70 {
    margin-right: -70px !important;
}

.mnr-80 {
    margin-right: -80px !important;
}

.mnr-90 {
    margin-right: -90px !important;
}

.mnr-100 {
    margin-right: -100px !important;
}

.mnb-0 {
    margin-bottom: 0px !important;
}

.mnb-1 {
    margin-bottom: -1px !important;
}

.mnb-2 {
    margin-bottom: -2px !important;
}

.mnb-3 {
    margin-bottom: -3px !important;
}

.mnb-4 {
    margin-bottom: -4px !important;
}

.mnb-5 {
    margin-bottom: -5px !important;
}

.mnb-6 {
    margin-bottom: -6px !important;
}

.mnb-7 {
    margin-bottom: -7px !important;
}

.mnb-8 {
    margin-bottom: -8px !important;
}

.mnb-9 {
    margin-bottom: -9px !important;
}

.mnb-10 {
    margin-bottom: -10px !important;
}

.mnb-13 {
    margin-bottom: -13px !important;
}

.mnb-15 {
    margin-bottom: -15px !important;
}

.mnb-18 {
    margin-bottom: -18px !important;
}

.mnb-20 {
    margin-bottom: -20px !important;
}

.mnb-25 {
    margin-bottom: -25px !important;
}

.mnb-30 {
    margin-bottom: -30px !important;
}

.mnb-35 {
    margin-bottom: -35px !important;
}

.mnb-40 {
    margin-bottom: -40px !important;
}

.mnb-45 {
    margin-bottom: -45px !important;
}

.mnb-50 {
    margin-bottom: -50px !important;
}

.mnb-55 {
    margin-bottom: -55px !important;
}

.mnb-60 {
    margin-bottom: -60px !important;
}

.mnb-70 {
    margin-bottom: -70px !important;
}

.mnb-80 {
    margin-bottom: -80px !important;
}

.mnb-90 {
    margin-bottom: -90px !important;
}

.mnb-100 {
    margin-bottom: -100px !important;
}

/*
 * Opacity
 */
.op-0 {
    opacity: 0 !important;
}

.op-1 {
    opacity: 0.1 !important;
}

.op-2 {
    opacity: 0.2 !important;
}

.op-3 {
    opacity: 0.3 !important;
}

.op-4 {
    opacity: 0.4 !important;
}

.op-5 {
    opacity: 0.5 !important;
}

.op-6 {
    opacity: 0.6 !important;
}

.op-7 {
    opacity: 0.7 !important;
}

.op-8 {
    opacity: 0.8 !important;
}

.op-9 {
    opacity: 0.9 !important;
}

.op-10 {
    opacity: 1 !important;
}

/*------------------------------------------------------------------

  Navbar

 -------------------------------------------------------------------*/
.dk-navbar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100px;
    padding: 29px 35px;
    font-family: "Lato", sans-serif;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 0 transparent;
            box-shadow: 0 1px 0 transparent;
    -webkit-transition: background-color .2s ease;
    -o-transition: background-color .2s ease;
    transition: background-color .2s ease;
    z-index: 1000;
}

@media screen and (max-width: 575px) {
    .dk-navbar {
        height: auto;
        padding: 28px 10px;
    }
}

.dk-navbar .dk-navbar-burger {
    display: none;
    width: 35px;
    height: 24px;
    cursor: pointer;
}

.dk-navbar .dk-navbar-burger > span {
    position: relative;
    display: block;
    width: 24px;
    height: 2px;
    background-color: #131313;
    -webkit-transition: background-color .2s ease, visibility .2s ease, opacity .2s ease, -webkit-transform .2s ease;
    transition: background-color .2s ease, visibility .2s ease, opacity .2s ease, -webkit-transform .2s ease;
    -o-transition: background-color .2s ease, visibility .2s ease, transform .2s ease, opacity .2s ease;
    transition: background-color .2s ease, visibility .2s ease, transform .2s ease, opacity .2s ease;
    transition: background-color .2s ease, visibility .2s ease, transform .2s ease, opacity .2s ease, -webkit-transform .2s ease;
}

.dk-navbar .dk-navbar-burger > span + span {
    margin-top: 5px;
}

.dk-navbar .dk-navbar-burger > span:nth-child(2) {
    width: 16px;
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
}

.dk-navbar .dk-navbar-burger:hover > span {
    background-color: rgba(255, 255, 255, 0.2);
}

.dk-navbar .dk-navbar-burger:hover > span:nth-child(2) {
    -webkit-transform: translateX(-7px);
        -ms-transform: translateX(-7px);
            transform: translateX(-7px);
}

.dk-navbar:not(.dk-navbar-fullscreen) .dk-nav-content ul > li.dk-drop-item:hover > .dk-dropdown,
.dk-navbar:not(.dk-navbar-fullscreen) .dk-nav-content ul > li.dk-drop-item:hover > .dk-dropdown-multi {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
}

.dk-navbar:not(.dk-navbar-fullscreen) .dk-nav-content ul > li.dk-drop-item:hover > .dk-dropdown > .dk-dropdown,
.dk-navbar:not(.dk-navbar-fullscreen) .dk-nav-content ul > li.dk-drop-item:hover > .dk-dropdown-multi > .dk-dropdown {
    visibility: visible;
    opacity: 1;
}

.dk-navbar:not(.dk-navbar-fullscreen) .dk-nav-content ul > li.dk-drop-item:hover > .dk-dropdown > .dk-drop-item::after,
.dk-navbar:not(.dk-navbar-fullscreen) .dk-nav-content ul > li.dk-drop-item:hover > .dk-dropdown-multi > .dk-drop-item::after {
    display: none;
}

.dk-navbar:not(.dk-navbar-fullscreen) .dk-nav-content ul > li.dk-drop-item:hover::after {
    content: "";
    position: absolute;
    top: 100%;
    right: -10px;
    bottom: auto;
    left: -10px;
    height: 30px;
}

.dk-navbar:not(.dk-navbar-fullscreen) .dk-nav-content .dk-dropdown,
.dk-navbar:not(.dk-navbar-fullscreen) .dk-nav-content .dk-dropdown-multi {
    visibility: hidden;
    opacity: 0;
}

.dk-navbar:not(.dk-navbar-fullscreen) .dk-nav-content .dk-dropdown > .dk-drop-item::before,
.dk-navbar:not(.dk-navbar-fullscreen) .dk-nav-content .dk-dropdown-multi > .dk-drop-item::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    left: auto;
    width: 2px;
    height: 2px;
    margin-top: -1px;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-transition: background-color .2s ease;
    -o-transition: background-color .2s ease;
    transition: background-color .2s ease;
}

.dk-navbar:not(.dk-navbar-fullscreen) .dk-nav-content .dk-dropdown > .dk-drop-item:hover::before, .dk-navbar:not(.dk-navbar-fullscreen) .dk-nav-content .dk-dropdown > .dk-drop-item.active::before,
.dk-navbar:not(.dk-navbar-fullscreen) .dk-nav-content .dk-dropdown-multi > .dk-drop-item:hover::before,
.dk-navbar:not(.dk-navbar-fullscreen) .dk-nav-content .dk-dropdown-multi > .dk-drop-item.active::before {
    background-color: rgba(255, 255, 255, 0.2);
}

.dk-navbar:not(.dk-navbar-fullscreen) .dk-nav-content .dk-dropdown-multi {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding: 0;
}

.dk-navbar:not(.dk-navbar-fullscreen) .dk-nav-content .dk-dropdown-multi > .dk-dropdown {
    position: static;
    margin-top: 0;
    -webkit-transform: translate(0);
        -ms-transform: translate(0);
            transform: translate(0);
}

.dk-navbar > .container,
.dk-navbar > .container-fluid {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
}

.dk-navbar .dk-nav-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
}

.dk-navbar .dk-nav-content ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 100%;
    padding: 0;
    margin: 0;
}

.dk-navbar .dk-nav-content ul > li {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    list-style: none;
}

.dk-navbar .dk-nav-content ul > li > a {
    display: block;
}

.dk-navbar .dk-nav-content .dk-nav.dk-nav-align-right {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}

.dk-navbar .dk-nav-content .dk-nav .dk-drop-item.active > a {
    color: rgba(255, 255, 255, 0.2);
}

.dk-navbar .dk-nav-content .dk-nav > li {
    margin-right: 30px;
}

.dk-navbar .dk-nav-content .dk-nav > li:last-child {
    margin-right: 0;
}

.dk-navbar .dk-nav-content .dk-nav a {
    padding: 10px 0;
    font-size: .89rem;
    font-weight: 500;
    color: #131313;
    text-transform: uppercase;
    letter-spacing: .02em;
}

.dk-navbar .dk-nav-content .dk-nav a:hover, .dk-navbar .dk-nav-content .dk-nav a:focus, .dk-navbar .dk-nav-content .dk-nav a.active {
    color: rgba(100, 100, 100, 0.5);
    text-decoration: none;
}

.dk-navbar .dk-nav-content .dk-nav-logo {
    min-width: auto;
    font-family: "Lato", sans-serif;
    font-size: 1.85rem;
    line-height: 1;
    color: #131313;
    text-decoration: none;
    white-space: nowrap;
    letter-spacing: .054em;
    -webkit-transition: color .2s ease;
    -o-transition: color .2s ease;
    transition: color .2s ease;
    background-image: url("/assets/img/logo_sizes/elevated_black_filled_280x69.png");
    background-repeat: no-repeat;
    background-size: 162px 40px;
}

.dk-navbar .dk-nav-content .dk-nav-logo:hover, .dk-navbar .dk-nav-content .dk-nav-logo:focus, .dk-navbar .dk-nav-content .dk-nav-logo:active {
    color: rgba(255, 255, 255, 0.2);
    text-decoration: none;
}

.dk-navbar .dk-nav-content .dk-dropdown,
.dk-navbar .dk-nav-content .dk-dropdown-multi {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    top: 100%;
    right: auto;
    bottom: auto;
    left: -12px;
    width: auto;
    padding: 17px 0;
    margin-top: 29px;
    background-color: #202020;
    border-radius: 0;
    -webkit-transition: visibility .2s ease, opacity .2s ease, -webkit-transform .2s ease;
    transition: visibility .2s ease, opacity .2s ease, -webkit-transform .2s ease;
    -o-transition: visibility .2s ease, opacity .2s ease, transform .2s ease;
    transition: visibility .2s ease, opacity .2s ease, transform .2s ease;
    transition: visibility .2s ease, opacity .2s ease, transform .2s ease, -webkit-transform .2s ease;
    -webkit-transform: translateY(15px);
        -ms-transform: translateY(15px);
            transform: translateY(15px);
}

.dk-navbar .dk-nav-content .dk-dropdown > li,
.dk-navbar .dk-nav-content .dk-dropdown-multi > li {
    display: block;
    width: 100%;
    padding: 8px 40px 8px 27px;
}

.dk-navbar .dk-nav-content .dk-dropdown > li.active > a,
.dk-navbar .dk-nav-content .dk-dropdown-multi > li.active > a {
    color: rgba(255, 255, 255, 0.2);
}

.dk-navbar .dk-nav-content .dk-dropdown > li a,
.dk-navbar .dk-nav-content .dk-dropdown-multi > li a {
    padding: 0 5px;
    font-size: 1rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.85);
    text-transform: none;
    white-space: nowrap;
}

.dk-navbar .dk-nav-content .dk-dropdown > li > .dk-dropdown,
.dk-navbar .dk-nav-content .dk-dropdown-multi > li > .dk-dropdown {
    top: 0;
    left: 100%;
    margin-top: -17px;
}

.dk-navbar.dk-navbar-fullscreen .dk-nav {
    display: none;
}

.dk-navbar.dk-navbar-fullscreen .dk-navbar-burger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

@media screen and (max-width: 1200px) {
    .dk-navbar.dk-navbar-xl-fullscreen .dk-nav {
        display: none;
    }
    .dk-navbar.dk-navbar-xl-fullscreen .dk-navbar-burger {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
}

@media screen and (max-width: 992px) {
    .dk-navbar.dk-navbar-lg-fullscreen .dk-nav {
        display: none;
    }
    .dk-navbar.dk-navbar-lg-fullscreen .dk-navbar-burger {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
}

@media screen and (max-width: 767px) {
    .dk-navbar.dk-navbar-md-fullscreen .dk-nav {
        display: none;
    }
    .dk-navbar.dk-navbar-md-fullscreen .dk-navbar-burger {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
}

@media screen and (max-width: 575px) {
    .dk-navbar.dk-navbar-sm-fullscreen .dk-nav {
        display: none;
    }
    .dk-navbar.dk-navbar-sm-fullscreen .dk-navbar-burger {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
}

.dk-navbar.dk-navbar-transparent {
    background-color: inherit;
}

.dk-navbar.dk-navbar-scroll {
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.04);
            box-shadow: 0 1px 0 rgba(0, 0, 0, 0.04);
}

.dk-navbar.dk-navbar-white:not(.dk-navbar-transparent) .dk-navbar-burger > span {
    background-color: #131313;
}

.dk-navbar.dk-navbar-white:not(.dk-navbar-transparent) .dk-navbar-burger:hover > span {
    background-color: rgba(255, 255, 255, 0.2);
}

.dk-navbar.dk-navbar-white:not(.dk-navbar-transparent) .dk-navbar-burger:hover > span:nth-child(2) {
    -webkit-transform: translateX(-8px);
        -ms-transform: translateX(-8px);
            transform: translateX(-8px);
}

.dk-navbar.dk-navbar-white:not(.dk-navbar-transparent) .dk-nav-logo {
    color: #131313;
   background-image: url("/assets/img/logo_sizes/elevated_black_filled_280x69.png");
}

.dk-navbar.dk-navbar-white:not(.dk-navbar-transparent) .dk-nav-logo:hover, .dk-navbar.dk-navbar-white:not(.dk-navbar-transparent) .dk-nav-logo:focus, .dk-navbar.dk-navbar-white:not(.dk-navbar-transparent) .dk-nav-logo.active {
    color: rgba(255, 255, 255, 0.2);
    text-decoration: none;

}

.dk-navbar.dk-navbar-white:not(.dk-navbar-transparent) .dk-nav > li > a {
    color: #131313;

}

.dk-navbar.dk-navbar-white:not(.dk-navbar-transparent) .dk-nav > li > a:hover, .dk-navbar.dk-navbar-white:not(.dk-navbar-transparent) .dk-nav > li > a:focus, .dk-navbar.dk-navbar-white:not(.dk-navbar-transparent) .dk-nav > li > a.active {
    color: rgba(255, 255, 255, 0.2);
    text-decoration: none;
}

.dk-navbar.dk-navbar-white .dk-navbar-burger > span {
    background-color: #fff;
    background-image: url("/assets/img/logo_sizes/elevated_white_280x69.png");
}

.dk-navbar.dk-navbar-white .dk-navbar-burger:hover > span {
    background-color: rgba(255, 255, 255, 0.2);
}

.dk-navbar.dk-navbar-white .dk-nav-logo {
    color: #fff;
    background-image: url("/assets/img/logo_sizes/elevated_white_280x69.png");
}

.dk-navbar.dk-navbar-white .dk-nav a {
    color: #fff;
}

.dk-navbar.dk-navbar-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
}

/*------------------------------------------------------------------

  Navbar Fullscreen

 -------------------------------------------------------------------*/
div.dk-navbar-fullscreen,
div.dk-navbar-fullscreen.dk-navbar-fullscreen {
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh;
    padding: 60px 20px;
    overflow-x: hidden;
    overflow-y: scroll;
    visibility: hidden;
    background-color: #202020;
    opacity: 0;
    -webkit-transition: visibility .3s ease, opacity .3s ease;
    -o-transition: visibility .3s ease, opacity .3s ease;
    transition: visibility .3s ease, opacity .3s ease;
    z-index: 1001;
}

div.dk-navbar-fullscreen::-webkit-scrollbar-track,
div.dk-navbar-fullscreen.dk-navbar-fullscreen::-webkit-scrollbar-track {
    background-color: #202020;
}

div.dk-navbar-fullscreen::-webkit-scrollbar,
div.dk-navbar-fullscreen.dk-navbar-fullscreen::-webkit-scrollbar {
    width: 0;
    background-color: #202020;
}

div.dk-navbar-fullscreen::-webkit-scrollbar-thumb,
div.dk-navbar-fullscreen.dk-navbar-fullscreen::-webkit-scrollbar-thumb {
    background-color: #fff;
}

div.dk-navbar-fullscreen .dk-nav-content,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-nav-content {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: calc(100% - 30px);
    padding: 92px 0 130px;
}

div.dk-navbar-fullscreen > .container,
div.dk-navbar-fullscreen > .container-fluid,
div.dk-navbar-fullscreen.dk-navbar-fullscreen > .container,
div.dk-navbar-fullscreen.dk-navbar-fullscreen > .container-fluid {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 100%;
    min-height: 550px;
}

div.dk-navbar-fullscreen .dk-nav,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-nav {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: auto;
    min-height: 370px;
    text-align: center;
    opacity: .2;
    -webkit-transition: opacity .3s ease, -webkit-transform .3s ease;
    transition: opacity .3s ease, -webkit-transform .3s ease;
    -o-transition: transform .3s ease, opacity .3s ease;
    transition: transform .3s ease, opacity .3s ease;
    transition: transform .3s ease, opacity .3s ease, -webkit-transform .3s ease;
    -webkit-transform: translateY(50px);
        -ms-transform: translateY(50px);
            transform: translateY(50px);
}

div.dk-navbar-fullscreen .dk-nav.dk-nav-align-right, div.dk-navbar-fullscreen .dk-nav.dk-nav-align-left,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-nav.dk-nav-align-right,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-nav.dk-nav-align-left {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

div.dk-navbar-fullscreen .dk-nav li,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-nav li {
    position: static;
    display: block;
    width: 100%;
    margin-right: 0;
}

div.dk-navbar-fullscreen .dk-nav li.dk-drop-item > a,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-nav li.dk-drop-item > a {
    position: relative;
}

div.dk-navbar-fullscreen .dk-nav li.dk-drop-item > a::after,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-nav li.dk-drop-item > a::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -3px;
    border: 3px solid transparent;
    border-left: 3px solid rgba(255, 255, 255, 0.3);
    -webkit-transition: border-color .2s ease;
    -o-transition: border-color .2s ease;
    transition: border-color .2s ease;
}

div.dk-navbar-fullscreen .dk-nav li.dk-drop-item > a:hover::after,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-nav li.dk-drop-item > a:hover::after {
    border-left-color: #cfbfb2;
}

div.dk-navbar-fullscreen .dk-nav li.dk-drop-item.active > a::after,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-nav li.dk-drop-item.active > a::after {
    border-left-color: #cfbfb2;
}

div.dk-navbar-fullscreen .dk-nav li.dk-drop-item > .dk-dropdown,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-nav li.dk-drop-item > .dk-dropdown {
    background-color: #202020;
}

div.dk-navbar-fullscreen .dk-nav li.back > a,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-nav li.back > a {
    position: relative;
    display: block;
}

div.dk-navbar-fullscreen .dk-nav li.back > a::after,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-nav li.back > a::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -3px;
    border: 3px solid transparent;
    border-right: 3px solid #fff;
}

div.dk-navbar-fullscreen .dk-nav a,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-nav a {
    padding: 5px 60px;
    font-size: 1.74rem;
    color: rgba(255, 255, 255, 0.85);
    text-transform: none;
}

@media screen and (max-width: 440px) {
    div.dk-navbar-fullscreen .dk-nav a,
    div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-nav a {
        font-size: 1.6rem;
    }
}

div.dk-navbar-fullscreen .dk-navbar-burger,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-navbar-burger {
    position: absolute;
    top: -24px;
    right: 20px;
}

@media screen and (max-width: 575px) {
    div.dk-navbar-fullscreen .dk-navbar-burger,
    div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-navbar-burger {
        top: -32px;
        right: 10px;
    }
}

div.dk-navbar-fullscreen .dk-navbar-burger > span,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-navbar-burger > span {
    background-color: #fff;
}

div.dk-navbar-fullscreen .dk-navbar-burger:hover > span,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-navbar-burger:hover > span {
    background-color: rgba(255, 255, 255, 0.2);
}

div.dk-navbar-fullscreen .dk-dropdown,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    top: 0;
    right: -40%;
    bottom: 0;
    left: -40%;
    width: auto;
    min-width: inherit;
    height: 370px;
    padding: 0;
    margin: 0 0 -60px 0;
    overflow-x: hidden;
    overflow-y: scroll;
    visibility: hidden;
    background-color: rgba(32, 32, 32, 0);
    opacity: 0;
    -webkit-transition: visibility .35s ease, opacity .35s ease, -webkit-transform .35s ease;
    transition: visibility .35s ease, opacity .35s ease, -webkit-transform .35s ease;
    -o-transition: transform .35s ease, visibility .35s ease, opacity .35s ease;
    transition: transform .35s ease, visibility .35s ease, opacity .35s ease;
    transition: transform .35s ease, visibility .35s ease, opacity .35s ease, -webkit-transform .35s ease;
    -webkit-transform: translateY(60px);
        -ms-transform: translateY(60px);
            transform: translateY(60px);
    z-index: 1;
}

@media screen and (max-width: 575px) {
    div.dk-navbar-fullscreen .dk-dropdown,
    div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-dropdown {
        right: -70px;
        left: -70px;
    }
}

@media screen and (max-width: 440px) {
    div.dk-navbar-fullscreen .dk-dropdown,
    div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-dropdown {
        right: -40px;
        left: -40px;
    }
}

div.dk-navbar-fullscreen .dk-dropdown.drop,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-dropdown.drop {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
}

div.dk-navbar-fullscreen .dk-dropdown > li,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-dropdown > li {
    display: inline-block;
    padding: 0;
}

div.dk-navbar-fullscreen .dk-dropdown > li a,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-dropdown > li a {
    display: block;
    padding: 5px 60px;
}

@media screen and (max-width: 575px) {
    div.dk-navbar-fullscreen .dk-dropdown > li a,
    div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-dropdown > li a {
        padding: 5px 20px;
    }
}

div.dk-navbar-fullscreen .dk-dropdown > li .dk-dropdown,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-dropdown > li .dk-dropdown {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 0 -60px 0;
}

div.dk-navbar-fullscreen .dk-dropdown::-webkit-scrollbar-track,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-dropdown::-webkit-scrollbar-track {
    background-color: #202020;
}

div.dk-navbar-fullscreen .dk-dropdown::-webkit-scrollbar,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-dropdown::-webkit-scrollbar {
    width: 3px;
    background-color: #202020;
}

div.dk-navbar-fullscreen .dk-dropdown::-webkit-scrollbar-thumb,
div.dk-navbar-fullscreen.dk-navbar-fullscreen .dk-dropdown::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
}

div.dk-navbar-fullscreen.dk-open-fullscreen,
div.dk-navbar-fullscreen.dk-navbar-fullscreen.dk-open-fullscreen {
    visibility: visible;
    opacity: 1;
}

@media screen and (min-width: 768px) {
    div.dk-navbar-fullscreen.dk-open-fullscreen::-webkit-scrollbar,
    div.dk-navbar-fullscreen.dk-navbar-fullscreen.dk-open-fullscreen::-webkit-scrollbar {
        width: 10px;
    }
}

div.dk-navbar-fullscreen.dk-open-fullscreen .dk-nav,
div.dk-navbar-fullscreen.dk-navbar-fullscreen.dk-open-fullscreen .dk-nav {
    opacity: 1;
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
}

div.dk-navbar-fullscreen.dk-open-fullscreen .dk-navbar-burger > span:nth-child(1),
div.dk-navbar-fullscreen.dk-navbar-fullscreen.dk-open-fullscreen .dk-navbar-burger > span:nth-child(1) {
    -webkit-transform: rotate(45deg) translate(5px, 5px);
        -ms-transform: rotate(45deg) translate(5px, 5px);
            transform: rotate(45deg) translate(5px, 5px);
}

div.dk-navbar-fullscreen.dk-open-fullscreen .dk-navbar-burger > span:nth-child(2),
div.dk-navbar-fullscreen.dk-navbar-fullscreen.dk-open-fullscreen .dk-navbar-burger > span:nth-child(2) {
    visibility: hidden;
    opacity: 0;
}

div.dk-navbar-fullscreen.dk-open-fullscreen .dk-navbar-burger > span:nth-child(3),
div.dk-navbar-fullscreen.dk-navbar-fullscreen.dk-open-fullscreen .dk-navbar-burger > span:nth-child(3) {
    -webkit-transform: rotate(-45deg) translate(5px, -5px);
        -ms-transform: rotate(-45deg) translate(5px, -5px);
            transform: rotate(-45deg) translate(5px, -5px);
}

/*------------------------------------------------------------------

  Header

 -------------------------------------------------------------------*/
.dk-header .dk-header-info {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
    padding: 100px 85px;
    z-index: 1;
}

.dk-header .dk-header-info > div {
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .dk-header .dk-header-info {
        padding: 40px;
    }
}

@media screen and (max-width: 992px) {
    .dk-header .dk-header-info {
        padding: 80px 40px;
    }
}

.dk-header .dk-header-info-style-2 {
    padding: 60px 100px 60px 0;
}

@media screen and (max-width: 1200px) {
    .dk-header .dk-header-info-style-2 {
        padding: 40px 60px 40px 0;
    }
}

@media screen and (max-width: 992px) {
    .dk-header .dk-header-info-style-2 {
        padding: 60px 0;
    }
}

/*------------------------------------------------------------------

  Portfolio

 -------------------------------------------------------------------*/
.dk-portfolio-item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    overflow: hidden;
}

.dk-portfolio-item .dk-portfolio-item-image {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
}

.dk-portfolio-item .dk-portfolio-item-image img {
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    font-family: "object-fit: cover";
    -o-object-fit: cover;
       object-fit: cover;
}

.dk-portfolio-item .dk-portfolio-item-image .dk-portfolio-item-image-size {
    display: block;
}

.dk-portfolio-item .dk-portfolio-item-image .dk-portfolio-item-image-size ~ img {
    position: absolute;
    top: 0;
    left: 0;
}

.dk-portfolio-item .dk-portfolio-item-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    z-index: 1;
}

.dk-portfolio-item .dk-portfolio-item-info .dk-portfolio-item-title {
    display: block;
    margin-bottom: 14px;
    font-size: 1.5rem;
    -webkit-transition: color .3s ease;
    -o-transition: color .3s ease;
    transition: color .3s ease;
}

.dk-portfolio-item .dk-portfolio-item-info .dk-portfolio-item-category {
    display: block;
    padding: 0;
    margin: 0;
    font-size: .8rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .06em;
    -webkit-transition: color .3s ease;
    -o-transition: color .3s ease;
    transition: color .3s ease;
}

.dk-portfolio-item .dk-portfolio-item-info .dk-portfolio-item-category > li,
.dk-portfolio-item .dk-portfolio-item-info .dk-portfolio-item-category > span,
.dk-portfolio-item .dk-portfolio-item-info .dk-portfolio-item-category > span  a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    list-style: none;
}

.dk-portfolio-item .dk-portfolio-item-info .dk-portfolio-item-category > li::after,
.dk-portfolio-item .dk-portfolio-item-info .dk-portfolio-item-category > span::after,
.dk-portfolio-item .dk-portfolio-item-info .dk-portfolio-item-category > span::after a {
    content: "/";
    display: inline-block;
    margin: 0 7px;
    opacity: .4;
}

.dk-portfolio-item .dk-portfolio-item-info .dk-portfolio-item-category > li:last-child::after,
.dk-portfolio-item .dk-portfolio-item-info .dk-portfolio-item-category > span:last-child::after {
    display: none;
}

.dk-portfolio-item .dk-portfolio-item-info .dk-portfolio-item-icon {
    font-size: 3.4rem;
}

.dk-portfolio-item .dk-portfolio-item-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    transition: opacity .3s ease;
    z-index: 1;
}

.dk-portfolio-item.dk-portfolio-item-center .dk-portfolio-item-info {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
}

.dk-portfolio-item.dk-portfolio-item-right .dk-portfolio-item-info {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    text-align: right;
}


.dk-portfolio-item.dk-portfolio-item-dark .dk-portfolio-item-info .dk-portfolio-item-title,
.dk-portfolio-item.dk-portfolio-item-dark .dk-portfolio-item-info .dk-portfolio-item-icon,
.dk-portfolio-item.dk-portfolio-item-dark .dk-portfolio-item-info .dk-portfolio-item-title a {
    color: #fff;
    text-decoration: none;
}

.dk-portfolio-item.dk-portfolio-item-dark .dk-portfolio-item-info .dk-portfolio-item-title a:hover {
    color: rgba(50, 50, 50, 0.5);
}

.dk-portfolio-item.dk-portfolio-item-dark .dk-portfolio-item-info .dk-portfolio-item-category,
.dk-portfolio-item.dk-portfolio-item-dark .dk-portfolio-item-info .dk-portfolio-item-category a {
    color: #c8c8c8;
    text-decoration: none;
}

.dk-portfolio-item.dk-portfolio-item-dark .dk-portfolio-item-info .dk-portfolio-item-category a:hover {
    color: #eaeaea;
}

.dk-portfolio-item.dk-portfolio-item-light .dk-portfolio-item-info .dk-portfolio-item-title,
.dk-portfolio-item.dk-portfolio-item-light .dk-portfolio-item-info .dk-portfolio-item-icon,
.dk-portfolio-item.dk-portfolio-item-light .dk-portfolio-item-info .dk-portfolio-item-title a {
    color: #131313;
    text-decoration: none;
}

.dk-portfolio-item.dk-portfolio-item-light .dk-portfolio-item-info .dk-portfolio-item-title a:hover {
    color: rgba(50, 50, 50, 0.5);
}

.dk-portfolio-item.dk-portfolio-item-light .dk-portfolio-item-info .dk-portfolio-item-category,
.dk-portfolio-item.dk-portfolio-item-light .dk-portfolio-item-info .dk-portfolio-item-category a {
    color: #6c6c6c;
    text-decoration: none;
}

.dk-portfolio-item.dk-portfolio-item-light .dk-portfolio-item-info .dk-portfolio-item-category a:hover {
    color: rgba(50, 50, 50, 0.5);
}

.dk-portfolio-item.dk-portfolio-item-white .dk-portfolio-item-info .dk-portfolio-item-title,
.dk-portfolio-item.dk-portfolio-item-white .dk-portfolio-item-info .dk-portfolio-item-icon,
.dk-portfolio-item.dk-portfolio-item-white .dk-portfolio-item-info .dk-portfolio-item-title a,
.dk-portfolio-item.dk-portfolio-item-white .dk-portfolio-item-info .dk-portfolio-item-category,
.dk-portfolio-item.dk-portfolio-item-white .dk-portfolio-item-info .dk-portfolio-item-category a {
    color: #fff;
    text-decoration: none;
}

.dk-portfolio-item.dk-portfolio-item-white .dk-portfolio-item-info .dk-portfolio-item-title a:hover {
    color: rgba(50, 50, 50, 0.5);
}

.dk-portfolio-item:hover {
    text-decoration: none;
}

.dk-portfolio-item:hover .dk-portfolio-item-overlay {
    opacity: 1;
}

.dk-portfolio-item-style-1 .dk-portfolio-item-image img {
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    -o-transition: transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}

.dk-portfolio-item-style-1 .dk-portfolio-item-info {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 40px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility .3s ease, opacity .3s ease;
    -o-transition: visibility .3s ease, opacity .3s ease;
    transition: visibility .3s ease, opacity .3s ease;
}

.dk-portfolio-item-style-1 .dk-portfolio-item-info .dk-portfolio-item-title,
.dk-portfolio-item-style-1 .dk-portfolio-item-info .dk-portfolio-item-icon {
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    -o-transition: transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease;
    -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
            transform: translateY(20px);
}

.dk-portfolio-item-style-1 .dk-portfolio-item-info .dk-portfolio-item-category {
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    -o-transition: transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease;
    -webkit-transform: translateY(30px);
        -ms-transform: translateY(30px);
            transform: translateY(30px);
}

.dk-portfolio-item-style-1:hover .dk-portfolio-item-image img {
    -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
            transform: scale(1.05);
}

.dk-portfolio-item-style-1:hover .dk-portfolio-item-info {
    visibility: visible;
    opacity: 1;
}

.dk-portfolio-item-style-1:hover .dk-portfolio-item-info .dk-portfolio-item-title,
.dk-portfolio-item-style-1:hover .dk-portfolio-item-info .dk-portfolio-item-category,
.dk-portfolio-item-style-1:hover .dk-portfolio-item-info .dk-portfolio-item-icon {
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
}

.dk-portfolio-item-style-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.dk-portfolio-item-style-2 .dk-portfolio-item-info {
    position: static;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 24px 0 14px;
}

.dk-portfolio-item-style-2:hover .dk-portfolio-item-info .dk-portfolio-item-title {
    color: rgba(255, 255, 255, 0.2);
}

.dk-portfolio-item-style-6:hover .dk-portfolio-item-info-dark .dk-portfolio-item-title-dark {
    color: rgba(50, 50, 50, 0.5);
}

.dk-portfolio-item-style-3 .dk-portfolio-item-image img {
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    -o-transition: transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}

.dk-portfolio-item-style-3 .dk-portfolio-item-overlay {
    opacity: 1;
}

.dk-portfolio-item-style-3 .dk-portfolio-item-image-size {
    min-height: 200px;
}

.dk-portfolio-item-style-3 .dk-portfolio-item-info {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 40px;
}

@media screen and (max-width: 360px) {
    .dk-portfolio-item-style-3 .dk-portfolio-item-info {
        padding: 40px 20px;
    }
}

.dk-portfolio-item-style-3 .dk-portfolio-item-info .dk-portfolio-item-title,
.dk-portfolio-item-style-3 .dk-portfolio-item-info .dk-portfolio-item-icon {
    font-size: 2.14rem;
    color: #fff;
}

.dk-portfolio-item-style-3 .dk-portfolio-item-info .dk-portfolio-item-category {
    color: #fff;
}

.dk-portfolio-item-style-3 .dk-portfolio-item-info .dk-portfolio-item-icon {
    font-size: 3.4rem;
}

.dk-portfolio-item-style-3:hover .dk-portfolio-item-image img {
    -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
            transform: scale(1.05);
}

.dk-portfolio-item-style-4.dk-portfolio-item-light .dk-portfolio-item-image img {
    -webkit-filter: brightness(100%) grayscale(0);
            filter: brightness(100%) grayscale(0);
}

.dk-portfolio-item-style-4.dk-portfolio-item-light:hover .dk-portfolio-item-image img {
    -webkit-filter: brightness(110%) grayscale(1);
            filter: brightness(110%) grayscale(1);
}

.dk-portfolio-item-style-4 .dk-portfolio-item-image img {
    -webkit-filter: grayscale(0) brightness(100%) contrast(100%);
            filter: grayscale(0) brightness(100%) contrast(100%);
    -webkit-transition: -webkit-transform .4s ease, -webkit-filter .4s ease;
    transition: -webkit-transform .4s ease, -webkit-filter .4s ease;
    -o-transition: transform .4s ease, filter .4s ease;
    transition: transform .4s ease, filter .4s ease;
    transition: transform .4s ease, filter .4s ease, -webkit-transform .4s ease, -webkit-filter .4s ease;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}

.dk-portfolio-item-style-4 .dk-portfolio-item-info {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 40px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility .3s ease, opacity .3s ease, -webkit-transform .3s ease;
    transition: visibility .3s ease, opacity .3s ease, -webkit-transform .3s ease;
    -o-transition: visibility .3s ease, opacity .3s ease, transform .3s ease;
    transition: visibility .3s ease, opacity .3s ease, transform .3s ease;
    transition: visibility .3s ease, opacity .3s ease, transform .3s ease, -webkit-transform .3s ease;
    -webkit-transform: scale(1.4);
        -ms-transform: scale(1.4);
            transform: scale(1.4);
}

.dk-portfolio-item-style-4 .dk-portfolio-item-info .dk-portfolio-item-title,
.dk-portfolio-item-style-4 .dk-portfolio-item-info .dk-portfolio-item-icon,
.dk-portfolio-item-style-4 .dk-portfolio-item-info .dk-portfolio-item-category {
    opacity: 0;
    -webkit-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    transition: opacity .3s ease;
    -webkit-transition-delay: .32s;
         -o-transition-delay: .32s;
            transition-delay: .32s;
}

.dk-portfolio-item-style-4 .dk-portfolio-item-overlay {
    -webkit-transition: visibility .2s ease, opacity .2s ease;
    -o-transition: visibility .2s ease, opacity .2s ease;
    transition: visibility .2s ease, opacity .2s ease;
}

.dk-portfolio-item-style-4:hover .dk-portfolio-item-image img {
    -webkit-filter: grayscale(1) brightness(0.45) contrast(1.05);
            filter: grayscale(1) brightness(0.45) contrast(1.05);
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
}

.dk-portfolio-item-style-4:hover .dk-portfolio-item-info {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}

.dk-portfolio-item-style-4:hover .dk-portfolio-item-info .dk-portfolio-item-title,
.dk-portfolio-item-style-4:hover .dk-portfolio-item-info .dk-portfolio-item-icon,
.dk-portfolio-item-style-4:hover .dk-portfolio-item-info .dk-portfolio-item-category {
    opacity: 1;
}

.dk-portfolio-item-style-5 .dk-portfolio-item-image img {
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    -o-transition: transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
}

.dk-portfolio-item-style-5.dk-portfolio-item-right .dk-portfolio-item-info {
    top: 0;
    bottom: auto;
    -webkit-transform: translateY(-90%);
        -ms-transform: translateY(-90%);
            transform: translateY(-90%);
}

.dk-portfolio-item-style-5.dk-portfolio-item-right:hover .dk-portfolio-item-image img {
    -webkit-transform: translateY(5%);
        -ms-transform: translateY(5%);
            transform: translateY(5%);
}

.dk-portfolio-item-style-5.dk-portfolio-item-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.dk-portfolio-item-style-5.dk-portfolio-item-center .dk-portfolio-item-image img {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}

.dk-portfolio-item-style-5.dk-portfolio-item-center .dk-portfolio-item-info {
    top: auto;
    bottom: auto;
    -webkit-transition-delay: .1s;
         -o-transition-delay: .1s;
            transition-delay: .1s;
    -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
            transform: scale(1.2);
}

.dk-portfolio-item-style-5.dk-portfolio-item-center:hover .dk-portfolio-item-image img {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
}

.dk-portfolio-item-style-5.dk-portfolio-item-dark .dk-portfolio-item-info {
    background-color: rgba(32, 32, 32, 0.85);
}

.dk-portfolio-item-style-5.dk-portfolio-item-white .dk-portfolio-item-info {
    background-color: #202020;
}

.dk-portfolio-item-style-5 .dk-portfolio-item-info {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 40px;
    background-color: #fff;
    opacity: 0;
    -webkit-transition: opacity .3s ease, -webkit-transform .3s ease;
    transition: opacity .3s ease, -webkit-transform .3s ease;
    -o-transition: opacity .3s ease, transform .3s ease;
    transition: opacity .3s ease, transform .3s ease;
    transition: opacity .3s ease, transform .3s ease, -webkit-transform .3s ease;
    -webkit-transform: translateY(90%);
        -ms-transform: translateY(90%);
            transform: translateY(90%);
}

.dk-portfolio-item-style-5:hover .dk-portfolio-item-image img {
    -webkit-transform: translateY(-5%) scale(1);
        -ms-transform: translateY(-5%) scale(1);
            transform: translateY(-5%) scale(1);
}

.dk-portfolio-item-style-5:hover .dk-portfolio-item-info {
    opacity: 1;
    -webkit-transform: translateY(0) scale(1);
        -ms-transform: translateY(0) scale(1);
            transform: translateY(0) scale(1);
}

.dk-portfolio-item-style-6 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.dk-portfolio-item-style-6 .dk-portfolio-item-info {
    position: static;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 24px 0 14px;
}

.dk-portfolio-item-style-6:hover .dk-portfolio-item-info .dk-portfolio-item-title {
    color: rgba(255, 255, 255, 0.2);
}

.dk-portfolio-item-style-6:hover .dk-portfolio-item-info-dark .dk-portfolio-item-title {
    color: rgba(50, 50, 50, 0.5);
}

.dk-portfolio-item-style-fullscreen {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
    overflow: visible;
}

.dk-portfolio-item-style-fullscreen .dk-portfolio-item-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
}

.dk-portfolio-item-style-fullscreen .dk-portfolio-item-info .dk-portfolio-item-title {
    font-size: 3rem;
    letter-spacing: .01em;
}

@media screen and (max-width: 767px) {
    .dk-portfolio-item-style-fullscreen .dk-portfolio-item-info .dk-portfolio-item-title {
        font-size: 2.7rem;
    }
}

@media screen and (max-width: 575px) {
    .dk-portfolio-item-style-fullscreen .dk-portfolio-item-info .dk-portfolio-item-title {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 440px) {
    .dk-portfolio-item-style-fullscreen .dk-portfolio-item-info .dk-portfolio-item-title {
        font-size: 2.2rem;
    }
}

.dk-portfolio-item-style-fullscreen .dk-portfolio-item-info .dk-portfolio-item-title a {
    -webkit-transition: color .3s ease;
    -o-transition: color .3s ease;
    transition: color .3s ease;
}

.dk-portfolio-item-style-fullscreen .dk-portfolio-item-info .dk-portfolio-item-title a:hover, .dk-portfolio-item-style-fullscreen .dk-portfolio-item-info .dk-portfolio-item-title a:focus {
    color: rgba(255, 255, 255, 0.2);
}


.dk-portfolio-item-style-fullscreen .dk-portfolio-item-info-dark .dk-portfolio-item-title a:hover, .dk-portfolio-item-style-fullscreen .dk-portfolio-item-info-dark .dk-portfolio-item-title a:focus {
    color: rgba(50, 50, 50, 0.5);
}


.dk-portfolio-item-style-fullscreen .dk-portfolio-item-info .dk-portfolio-item-category {
    margin-bottom: 10px;
}

.dk-portfolio-item-style-fullscreen .dk-portfolio-fullscreen-btn {
    position: absolute;
    bottom: 50px;
    left: -52px;
}

@media screen and (max-width: 767px) {
    .dk-portfolio-item-style-fullscreen .dk-portfolio-fullscreen-btn {
        left: -2px;
    }
}

/*------------------------------------------------------------------

  Reviews

 -------------------------------------------------------------------*/
.dk-reviews {
    padding: 0 60px;
    color: #131313;
}

@media screen and (max-width: 575px) {
    .dk-reviews {
        padding: 0 10px;
    }
}

@media screen and (max-width: 440px) {
    .dk-reviews {
        padding: 0;
    }
}

.dk-reviews .dk-reviews-icon {
    margin-bottom: 55px;
}

.dk-reviews .dk-reviews-text {
    margin-bottom: 27px;
    font-size: 1.54rem;
    line-height: normal;
    color: #131313;
    letter-spacing: .01em;
}

.dk-reviews .dk-reviews-text p {
    color: inherit;
}

.dk-reviews .dk-reviews-name {
    font-size: .84rem;
    font-weight: 500;
    color: #858585;
    text-transform: uppercase;
}

.dk-reviews.dk-reviews-white .dk-reviews-text {
    color: #fff;
}

.dk-reviews.dk-reviews-white .dk-reviews-name {
    color: #c8c8c8;
}

/*------------------------------------------------------------------

  Numbers

 -------------------------------------------------------------------*/
.dk-numbers {
    padding: 10px 0;
    color: #fff;
    text-align: center;
}

.dk-numbers .dk-numbers-title {
    margin-top: -10px;
    font-size: 1.84rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.2);
    letter-spacing: .02em;
}

.dk-numbers .dk-numbers-text {
    margin-top: 9px;
    margin-bottom: -4px;
    font-size: .84rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .06em;
}

/*------------------------------------------------------------------

  Footer

 -------------------------------------------------------------------*/
.dk-footer {
    position: relative;
    background-color: #202020;
    z-index: 1;
}

.dk-footer.dk-footer-light {
    background-color: #eaeaea;
}

.dk-footer.dk-footer-light > .dk-footer-copyright {
    color: #7a7a7a;
    background-color: #f3f3f3;
}

.dk-footer > .dk-footer-copyright {
    padding: 27px;
    background-color: #171717;
}

.dk-footer-copyright {
    padding: 66px 0 68px;
    font-size: .95rem;
    color: #7a7a7a;
    text-align: center;
    background-color: #202020;
}

.dk-footer-copyright.dk-footer-light {
    color: #7a7a7a;
    background-color: #eaeaea;
}

/*------------------------------------------------------------------

  Widgets

 -------------------------------------------------------------------*/
.dk-widget,
.dk-widget-footer {
    overflow: hidden;
    font-family: "Lato", sans-serif;
}

.dk-widget ~ .dk-widget,
.dk-widget-footer ~ .dk-widget {
    margin-top: 30px;
}

@media screen and (max-width: 575px) {
    .dk-widget ~ .dk-widget,
    .dk-widget-footer ~ .dk-widget {
        margin-top: 20px;
    }
}

.dk-widget ul,
.dk-widget li,
.dk-widget-footer ul,
.dk-widget-footer li {
    padding: 0;
    margin: 0;
    list-style: none;
}

.dk-widget a,
.dk-widget-footer a {
    color: #858585;
    text-decoration: none;
    letter-spacing: .03em;
}

.dk-widget a:hover, .dk-widget a:focus,
.dk-widget-footer a:hover,
.dk-widget-footer a:focus {
    color: rgba(255, 255, 255, 0.2);
    text-decoration: none;
}

.dk-widget .dk-widget-title,
.dk-widget-footer .dk-widget-title {
    margin-bottom: 20px;
}

.dk-widget .dk-widget-title > *,
.dk-widget-footer .dk-widget-title > * {
    margin-bottom: 0;
}

.dk-widget .dk-widget-text,
.dk-widget-footer .dk-widget-text {
    letter-spacing: .03em;
}

.dk-widget .dk-widget-list,
.dk-widget-footer .dk-widget-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}

.dk-widget .dk-instagram,
.dk-widget-footer .dk-instagram {
    margin-top: -2px;
}

@media screen and (max-width: 767px) {
    .dk-widget,
    .dk-widget-footer {
        padding-bottom: 10px;
    }
    .dk-widget .dk-widget-content,
    .dk-widget-footer .dk-widget-content {
        text-align: center;
    }
}

.dk-widget-footer {
    margin-top: -8px;
    margin-bottom: -10px;
}

.dk-widget-footer a {
    color: #c8c8c8;
}

.dk-widget-footer a:hover, .dk-widget-footer a:focus {
    color: #fff;
}

.dk-widget-footer .dk-widget-title {
    color: #fff;
}

.dk-widget-footer .dk-widget-title > * {
    color: inherit;
}

.dk-widget-footer .dk-widget-text {
    color: #c8c8c8;
}

.dk-widget-footer.dk-widget-footer-light a {
    color: #5e5e5e;
}

.dk-widget-footer.dk-widget-footer-light a:hover, .dk-widget-footer.dk-widget-footer-light a:focus {
    color: #000;
}

.dk-widget-footer.dk-widget-footer-light .dk-widget-title {
    color: #131313;
}

.dk-widget-footer.dk-widget-footer-light .dk-widget-text {
    color: #5e5e5e;
}

/* Sidebars */
.dk-sidebar {
    position: relative;
    height: 100%;
}

/*------------------------------------------------------------------

  Social Links

 -------------------------------------------------------------------*/
.dk-social-links {
    padding: 0;
    margin: 0;
    margin-right: -2px;
    margin-left: -2px;
    list-style-type: none;
}

.dk-social-links::after {
    content: "";
    display: table;
    clear: both;
}

.dk-social-links > li {
    display: inline-block;
    margin-right: 10px;
}

.dk-social-links > li:last-child {
    margin-right: 0;
}

.dk-social-links > li > * {
    display: block;
    width: 24px;
    height: 30px;
    font-size: 1.2rem;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    -webkit-transition: .2s color;
    -o-transition: .2s color;
    transition: .2s color;
}

.dk-social-links > li > *, .dk-social-links > li > *:hover, .dk-social-links > li > *:focus, .dk-social-links > li > *:active {
    color: #131313;
}

.dk-social-links > li > *:hover, .dk-social-links > li > *.hover {
    color: #fff;
}

.dk-social-links > li > *.dk-social-behance {
    color: #fff;
}

.dk-social-links > li > *.dk-social-behance:hover, .dk-social-links > li > *.dk-social-behance.hover {
    color: #487cfb;
}

.dk-social-links > li > *.dk-social-bitbucket {
    color: #fff;
}

.dk-social-links > li > *.dk-social-bitbucket:hover, .dk-social-links > li > *.dk-social-bitbucket.hover {
    color: #36517e;
}

.dk-social-links > li > *.dk-social-dropbox {
    color: #fff;
}

.dk-social-links > li > *.dk-social-dropbox:hover, .dk-social-links > li > *.dk-social-dropbox.hover {
    color: #4d86d9;
}

.dk-social-links > li > *.dk-social-dribbble {
    color: #fff;
}

.dk-social-links > li > *.dk-social-dribbble:hover, .dk-social-links > li > *.dk-social-dribbble.hover {
    color: #cc4d86;
}

.dk-social-links > li > *.dk-social-deviantart {
    color: #fff;
}

.dk-social-links > li > *.dk-social-deviantart:hover, .dk-social-links > li > *.dk-social-deviantart.hover {
    color: #bac940;
}

.dk-social-links > li > *.dk-social-facebook {
    color: #fff;
}

.dk-social-links > li > *.dk-social-facebook:hover, .dk-social-links > li > *.dk-social-facebook.hover {
    color: #475995;
}

.dk-social-links > li > *.dk-social-flickr {
    color: #fff;
}

.dk-social-links > li > *.dk-social-flickr:hover, .dk-social-links > li > *.dk-social-flickr.hover {
    color: #db0180;
}

.dk-social-links > li > *.dk-social-foursquare {
    color: #fff;
}

.dk-social-links > li > *.dk-social-foursquare:hover, .dk-social-links > li > *.dk-social-foursquare.hover {
    color: #d94a75;
}

.dk-social-links > li > *.dk-social-github {
    color: #fff;
}

.dk-social-links > li > *.dk-social-github:hover, .dk-social-links > li > *.dk-social-github.hover {
    color: #464646;
}

.dk-social-links > li > *.dk-social-google-plus {
    color: #fff;
}

.dk-social-links > li > *.dk-social-google-plus:hover, .dk-social-links > li > *.dk-social-google-plus.hover {
    color: #c14d3d;
}

.dk-social-links > li > *.dk-social-instagram {
    color: #fff;
}

.dk-social-links > li > *.dk-social-instagram:hover, .dk-social-links > li > *.dk-social-instagram.hover {
    color: #d12054;
}

.dk-social-links > li > *.dk-social-linkedin {
    color: #fff;
}

.dk-social-links > li > *.dk-social-linkedin:hover, .dk-social-links > li > *.dk-social-linkedin.hover {
    color: #457ab3;
}

.dk-social-links > li > *.dk-social-medium {
    color: #fff;
}

.dk-social-links > li > *.dk-social-medium:hover, .dk-social-links > li > *.dk-social-medium.hover {
    color: #71c66d;
}

.dk-social-links > li > *.dk-social-odnoklassniki {
    color: #fff;
}

.dk-social-links > li > *.dk-social-odnoklassniki:hover, .dk-social-links > li > *.dk-social-odnoklassniki.hover {
    color: #d8732a;
}

.dk-social-links > li > *.dk-social-paypal {
    color: #fff;
}

.dk-social-links > li > *.dk-social-paypal:hover, .dk-social-links > li > *.dk-social-paypal.hover {
    color: #1d3283;
}

.dk-social-links > li > *.dk-social-pinterest {
    color: #fff;
}

.dk-social-links > li > *.dk-social-pinterest:hover, .dk-social-links > li > *.dk-social-pinterest.hover {
    color: #ae262b;
}

.dk-social-links > li > *.dk-social-rss {
    color: #fff;
}

.dk-social-links > li > *.dk-social-rss:hover, .dk-social-links > li > *.dk-social-rss.hover {
    color: #e06618;
}

.dk-social-links > li > *.dk-social-reddit {
    color: #fff;
}

.dk-social-links > li > *.dk-social-reddit:hover, .dk-social-links > li > *.dk-social-reddit.hover {
    color: #de470b;
}

.dk-social-links > li > *.dk-social-skype {
    color: #fff;
}

.dk-social-links > li > *.dk-social-skype:hover, .dk-social-links > li > *.dk-social-skype.hover {
    color: #62aded;
}

.dk-social-links > li > *.dk-social-soundcloud {
    color: #fff;
}

.dk-social-links > li > *.dk-social-soundcloud:hover, .dk-social-links > li > *.dk-social-soundcloud.hover {
    color: #df5611;
}

.dk-social-links > li > *.dk-social-slack {
    color: #fff;
}

.dk-social-links > li > *.dk-social-slack:hover, .dk-social-links > li > *.dk-social-slack.hover {
    color: #543b56;
}

.dk-social-links > li > *.dk-social-steam {
    color: #fff;
}

.dk-social-links > li > *.dk-social-steam:hover, .dk-social-links > li > *.dk-social-steam.hover {
    color: #272425;
}

.dk-social-links > li > *.dk-social-tumblr {
    color: #fff;
}

.dk-social-links > li > *.dk-social-tumblr:hover, .dk-social-links > li > *.dk-social-tumblr.hover {
    color: #384961;
}

.dk-social-links > li > *.dk-social-twitter {
    color: #fff;
}

.dk-social-links > li > *.dk-social-twitter:hover, .dk-social-links > li > *.dk-social-twitter.hover {
    color: #6da9de;
}

.dk-social-links > li > *.dk-social-vimeo {
    color: #fff;
}

.dk-social-links > li > *.dk-social-vimeo:hover, .dk-social-links > li > *.dk-social-vimeo.hover {
    color: #69b5e7;
}

.dk-social-links > li > *.dk-social-vk {
    color: #fff;
}

.dk-social-links > li > *.dk-social-vk:hover, .dk-social-links > li > *.dk-social-vk.hover {
    color: #657da0;
}

.dk-social-links > li > *.dk-social-wordpress {
    color: #fff;
}

.dk-social-links > li > *.dk-social-wordpress:hover, .dk-social-links > li > *.dk-social-wordpress.hover {
    color: #4072a1;
}

.dk-social-links > li > *.dk-social-youtube {
    color: #fff;
}

.dk-social-links > li > *.dk-social-youtube:hover, .dk-social-links > li > *.dk-social-youtube.hover {
    color: #c6271e;
}

.dk-social-links.dk-social-links-dark > li {
    margin-right: 5px;
}

.dk-social-links.dk-social-links-dark > li:last-child {
    margin-right: 0;
}

.dk-social-links.dk-social-links-dark > li > * {
    font-size: 1.1rem;
}

.dk-social-links.dk-social-links-dark > li > *, .dk-social-links.dk-social-links-dark > li > *:hover, .dk-social-links.dk-social-links-dark > li > *:focus, .dk-social-links.dk-social-links-dark > li > *:active {
    color: #858585;
}

.dk-social-links.dk-social-links-dark > li > *:hover, .dk-social-links.dk-social-links-dark > li > *.hover {
    color: #fff;
}

.dk-social-links.dk-social-links-light > li {
    margin-right: 5px;
}

.dk-social-links.dk-social-links-light > li:last-child {
    margin-right: 0;
}

.dk-social-links.dk-social-links-light > li > * {
    font-size: 1.1rem;
    color: #858585;
}

.dk-social-links.dk-social-links-light > li > *, .dk-social-links.dk-social-links-light > li > *:hover, .dk-social-links.dk-social-links-light > li > *:focus, .dk-social-links.dk-social-links-light > li > *:active {
    color: #858585;
}

.dk-social-links.dk-social-links-light > li > *:hover, .dk-social-links.dk-social-links-light > li > *.hover {
    color: #131313;
}

/*------------------------------------------------------------------

  Elements

 -------------------------------------------------------------------*/
/*---------------------------
  Element Buttons
 ----------------------------*/
.dk-btn {
    position: relative;
    display: inline-block;
    padding: 11px 26px;
    margin: 0;
    font-family: "Lato", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .07em;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: rgba(255, 255, 255, 0.0);
    border: 2px solid white;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.dk-btn:hover, .dk-btn.hover {
    background-color: rgba(255, 255, 255, 0.2);
    border: 2px solid white;
}

.dk-btn:hover, .dk-btn.hover, .dk-btn:active, .dk-btn.active, .dk-btn:focus {
    color: #fff;
    text-decoration: none;
}

.dk-btn-dark, .dk-btn-dark:hover, .dk-btn-dark.hover, .dk-btn-dark:active, .dk-btn-dark.active, .dk-btn-dark:focus {
    color: #222;
    border: 1px solid #444;
}

.dk-btn-hover-white:hover, .dk-btn-hover-white.hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.dk-btn-hover-white:hover, .dk-btn-hover-white.hover, .dk-btn-hover-white:active, .dk-btn-hover-white.active, .dk-btn-hover-white:focus {
    color: #131313;
    text-decoration: none;
}

.dk-btn-play {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 85px;
    min-width: 85px;
    height: 85px;
    min-height: 85px;
    padding: 0;
    border-radius: 100%;
}

.dk-btn-play::before {
    content: "";
    position: relative;
    display: block;
    right: -5px;
    border: 8px solid transparent;
    border-left: 10px solid #fff;
    -webkit-transition: border-color .3s ease;
    -o-transition: border-color .3s ease;
    transition: border-color .3s ease;
}

.dk-btn-play.dk-btn-hover-white:hover::before {
    border: 8px solid transparent;
    border-left: 10px solid #131313;
}

.dk-btn-xs {
    padding: 6px 15px;
    font-size: .8rem;
}

.dk-btn-sm {
    padding: 10px 25px;
    font-size: .9rem;
}

.dk-btn-md {
    padding: 18px 45px;
}

.dk-btn-lg {
    padding: 12px 83px;
}

.dk-btn-x2 {
    padding: 14px 25px;
    font-size: 1rem;
}

.dk-btn-x3 {
    padding: 18px 30px;
    font-size: 1.1rem;
}

.dk-btn-x4 {
    padding: 20px 45px;
    font-size: 1.2rem;
}

.dk-btn-block {
    display: block;
    width: 100%;
}

.dk-btn-load {
    position: relative;
}

.dk-btn-load::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 50%;
    width: 6px;
    height: 6px;
    margin-top: -15px;
    margin-right: 2px;
    -webkit-box-shadow: 0 6px 0 0 #131313, 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313;
            box-shadow: 0 6px 0 0 #131313, 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313;
    opacity: 0;
    -webkit-transition: opacity .4s ease;
    -o-transition: opacity .4s ease;
    transition: opacity .4s ease;
    -webkit-transition-delay: .1s;
         -o-transition-delay: .1s;
            transition-delay: .1s;
}

.dk-btn-load.dk-btn-loaded {
    color: #131313;
    cursor: default;
    background: none;
}

.dk-btn-load.dk-btn-loaded::before {
    display: none;
}

.dk-btn-load.dk-btn-loading {
    cursor: default;
    background: none;
}

.dk-btn-load.dk-btn-loading::before {
    opacity: 1;
    -webkit-animation: btn 1.5s infinite;
            animation: btn 1.5s infinite;
}

@-webkit-keyframes btn {
    0% {
        -webkit-box-shadow: 0 6px 0 0 #131313, 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313;
                box-shadow: 0 6px 0 0 #131313, 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313;
    }
    3% {
        -webkit-box-shadow: 0 6px 0 0 #131313, 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313;
                box-shadow: 0 6px 0 0 #131313, 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313;
    }
    33% {
        -webkit-box-shadow: 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313, 0 6px 0 0 #131313;
                box-shadow: 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313, 0 6px 0 0 #131313;
    }
    36% {
        -webkit-box-shadow: 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313, 0 6px 0 0 #131313;
                box-shadow: 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313, 0 6px 0 0 #131313;
    }
    66% {
        -webkit-box-shadow: 10px 16px 0 0 #131313, 0 16px 0 0 #131313, 0 6px 0 0 #131313, 10px 6px 0 0 #131313;
                box-shadow: 10px 16px 0 0 #131313, 0 16px 0 0 #131313, 0 6px 0 0 #131313, 10px 6px 0 0 #131313;
    }
    69% {
        -webkit-box-shadow: 10px 16px 0 0 #131313, 0 16px 0 0 #131313, 0 6px 0 0 #131313, 10px 6px 0 0 #131313;
                box-shadow: 10px 16px 0 0 #131313, 0 16px 0 0 #131313, 0 6px 0 0 #131313, 10px 6px 0 0 #131313;
    }
    100% {
        -webkit-box-shadow: 0 16px 0 0 #131313, 0 6px 0 0 #131313, 10px 6px 0 0 #131313, 10px 16px 0 0 #131313;
                box-shadow: 0 16px 0 0 #131313, 0 6px 0 0 #131313, 10px 6px 0 0 #131313, 10px 16px 0 0 #131313;
    }
}

@keyframes btn {
    0% {
        -webkit-box-shadow: 0 6px 0 0 #131313, 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313;
                box-shadow: 0 6px 0 0 #131313, 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313;
    }
    3% {
        -webkit-box-shadow: 0 6px 0 0 #131313, 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313;
                box-shadow: 0 6px 0 0 #131313, 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313;
    }
    33% {
        -webkit-box-shadow: 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313, 0 6px 0 0 #131313;
                box-shadow: 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313, 0 6px 0 0 #131313;
    }
    36% {
        -webkit-box-shadow: 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313, 0 6px 0 0 #131313;
                box-shadow: 10px 6px 0 0 #131313, 10px 16px 0 0 #131313, 0 16px 0 0 #131313, 0 6px 0 0 #131313;
    }
    66% {
        -webkit-box-shadow: 10px 16px 0 0 #131313, 0 16px 0 0 #131313, 0 6px 0 0 #131313, 10px 6px 0 0 #131313;
                box-shadow: 10px 16px 0 0 #131313, 0 16px 0 0 #131313, 0 6px 0 0 #131313, 10px 6px 0 0 #131313;
    }
    69% {
        -webkit-box-shadow: 10px 16px 0 0 #131313, 0 16px 0 0 #131313, 0 6px 0 0 #131313, 10px 6px 0 0 #131313;
                box-shadow: 10px 16px 0 0 #131313, 0 16px 0 0 #131313, 0 6px 0 0 #131313, 10px 6px 0 0 #131313;
    }
    100% {
        -webkit-box-shadow: 0 16px 0 0 #131313, 0 6px 0 0 #131313, 10px 6px 0 0 #131313, 10px 16px 0 0 #131313;
                box-shadow: 0 16px 0 0 #131313, 0 6px 0 0 #131313, 10px 6px 0 0 #131313, 10px 16px 0 0 #131313;
    }
}

/*---------------------------
  Element Feature
 ----------------------------*/
.dk-feature,
.dk-feature-1 {
    position: relative;
    display: table;
    width: 100%;
    min-height: 50px;
    padding: 5px;
}

.dk-feature > *,
.dk-feature-1 > * {
    display: table-cell;
    vertical-align: top;
}

.dk-feature .dk-feature-title,
.dk-feature-1 .dk-feature-title {
    margin-top: 8px;
    margin-bottom: 16px;
    font-weight: 500;
    letter-spacing: .07em;
}

.dk-feature .dk-feature-title a,
.dk-feature-1 .dk-feature-title a {
    color: inherit;
    text-decoration: none;
}

.dk-feature .dk-feature-cont,
.dk-feature-1 .dk-feature-cont {
    width: 100%;
    padding-top: 8px;
    padding-left: 38px;
}

.dk-feature .dk-feature-cont > *,
.dk-feature-1 .dk-feature-cont > * {
    font-size: inherit;
    color: inherit;
}

.dk-feature-1 {
    padding: 10px 5px;
    color: #fff;
    text-align: center;
    z-index: 1;
}

.dk-feature-1 .dk-feature-cont {
    padding: 0;
}

.dk-feature-1 .dk-feature-cont .dk-feature-icon {
    display: block;
    font-size: 3.1rem;
    color: rgba(255, 255, 255, 0.2);
}

.dk-feature-1 .dk-feature-cont .dk-feature-title {
    margin: 6px 0 8px;
    font-size: .8rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .1em;
}

.dk-feature-1 .dk-feature-cont .dk-feature-text {
    color: #c8c8c8;
    letter-spacing: .01em;
}

.dk-feature-1.dk-feature-style-2 .dk-feature-cont .dk-feature-title {
    color: #131313;
}

.dk-feature-1.dk-feature-style-2 .dk-feature-cont .dk-feature-text {
    color: #7a7a7a;
}

/*---------------------------
  Element Content Boxes
 ----------------------------*/
.dk-box,
.dk-box-1,
.dk-box-2,
.dk-box-3,
.dk-box-4,
.dk-box-5,
.dk-box-fullscreen {
    position: relative;
    display: block;
    height: 100%;
    z-index: 1;
}

.dk-box-1 {
    padding: 90px 0;
}

.dk-box-1.dk-padding-top {
    padding: 90px 0 0;
}

.dk-box-1.dk-padding-bot {
    padding: 0 0 90px;
}

.dk-box-2 {
    padding: 110px 0 110px;
}

.dk-box-2.dk-padding-top {
    padding: 110px 0 0;
}

.dk-box-2.dk-padding-bot {
    padding: 0 0 110px;
}

.dk-box-3 {
    padding: 125px 0;
}

.dk-box-3.dk-padding-top {
    padding: 125px 0 0;
}

.dk-box-3.dk-padding-bot {
    padding: 0 0 125px;
}

.dk-box-4 {
    padding: 235px 0;
}

.dk-box-4.dk-padding-top {
    padding: 235px 0 0;
}

.dk-box-4.dk-padding-bot {
    padding: 0 0 235px;
}

.dk-box-5 {
    padding: 60px 0;
}

.dk-box-5.dk-padding-top {
    padding: 60px 0 0;
}

.dk-box-5.dk-padding-bot {
    padding: 0 0 60px;
}

.dk-box-fullscreen {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 100vh;
    padding: 200px 0;
}

/*---------------------------
  Element Pagination
 ----------------------------*/
.dk-pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    padding: 20px 0;
    background-color: #f3f3f3;
}

.dk-pagination > .container,
.dk-pagination > .container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
}

.dk-pagination .dk-pagination-prev,
.dk-pagination .dk-pagination-next {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-width: 200px;
    font-size: .89rem;
    font-weight: 500;
    text-transform: uppercase;
    white-space: nowrap;
}

@media screen and (max-width: 767px) {
    .dk-pagination .dk-pagination-prev,
    .dk-pagination .dk-pagination-next {
        min-width: 50px;
        font-size: 0;
    }
}

.dk-pagination .dk-pagination-prev:hover, .dk-pagination .dk-pagination-prev:focus,
.dk-pagination .dk-pagination-next:hover,
.dk-pagination .dk-pagination-next:focus {
    color: rgba(255, 255, 255, 0.2);
    text-decoration: none;
}

.dk-pagination .dk-pagination-prev {
    padding: 20px 20px 20px 0;
}

.dk-pagination .dk-pagination-next {
    padding: 20px 0 20px 20px;
}

.dk-pagination .dk-pagination-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    padding: 20px;
}

.dk-pagination .dk-pagination-center:hover {
    color: rgba(255, 255, 255, 0.2);
}

.dk-pagination .dk-pagination-center:hover [class*="dk-icon"]::before,
.dk-pagination .dk-pagination-center:hover [class*="dk-icon"]::after {
    border-color: rgba(255, 255, 255, 0.2);
}

.dk-pagination .dk-pagination-prev.text-center,
.dk-pagination .dk-pagination-next.text-center,
.dk-pagination .dk-pagination-center.text-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.dk-pagination .dk-pagination-icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 10px;
    height: 20px;
    font-size: 1.7rem;
}

.dk-pagination .dk-pagination-next .dk-pagination-icon {
    margin-left: 10px;
}

.dk-pagination .dk-pagination-prev .dk-pagination-icon {
    margin-right: 10px;
}

/*---------------------------
  Element Blockquotes
 ----------------------------*/
.dk-blockquote {
    margin-bottom: 1.5rem;
    font-family: "PT Serif", serif;
    font-style: italic;
    font-weight: 500;
    color: #131313;
}

/*---------------------------
  Element Image Boxes
 ----------------------------*/
.dk-gallery .dk-gallery-item {
    position: relative;
    display: block;
    height: 100%;
    overflow: hidden;
}

.dk-gallery .dk-gallery-item > img {
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover";
    -o-object-fit: cover;
       object-fit: cover;
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    -o-transition: transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}

.dk-gallery .dk-gallery-item::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    background-color: rgba(32, 32, 32, 0.1);
    opacity: 0;
    -webkit-transition: visibility .3s ease, opacity .3s ease;
    -o-transition: visibility .3s ease, opacity .3s ease;
    transition: visibility .3s ease, opacity .3s ease;
}

.dk-gallery .dk-gallery-item:hover > img {
    -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
            transform: scale(1.05);
}

.dk-gallery .dk-gallery-item:hover::after {
    visibility: visible;
    opacity: 1;
}

/*---------------------------
  Element Forms
 ----------------------------*/
/* Placeholders */
::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
    color: inherit;
    opacity: .5;
}
:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
    color: inherit;
    opacity: .5;
}
::-ms-input-placeholder,
.form-control::-ms-input-placeholder {
    color: inherit;
    opacity: .5;
}
::placeholder,
.form-control::placeholder {
    color: inherit;
    opacity: .5;
}

/* Remove outline from focused buttons */
button:focus {
    outline: 0;
}

/* From Style 1 */
.dk-form {
    margin: 0;
}

.dk-form .form-group {
    margin-bottom: 20px;
}

.dk-form .form-control {
    height: 50px;
    padding: 12px 20px;
    font-family: "Lato", sans-serif;
    color: #5e5e5e;
    text-align: left;
    text-transform: none;
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 0;
    -webkit-transition: .2s border-color;
    -o-transition: .2s border-color;
    transition: .2s border-color;
}

.dk-form .form-control:hover, .dk-form .form-control.hover {
    border-color: rgba(255, 255, 255, 0.2);
}

.dk-form .form-control:focus, .dk-form .form-control.focus {
    color: #131313;
    border-color: #131313;
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.dk-form .form-control::-webkit-input-placeholder {
    color: #858585;
    opacity: 1;
}

.dk-form .form-control:-ms-input-placeholder {
    color: #858585;
    opacity: 1;
}

.dk-form .form-control::-ms-input-placeholder {
    color: #858585;
    opacity: 1;
}

.dk-form .form-control::placeholder {
    color: #858585;
    opacity: 1;
}

.dk-form textarea,
.dk-form textarea.form-control {
    height: 170px;
    min-height: 50px;
    max-height: 250px;
}

.dk-form label {
    font-family: "Lato", sans-serif;
    font-size: .9rem;
    text-transform: uppercase;
}

.dk-form select.form-control:not([size]):not([multiple]) {
    height: 46px;
}

.dk-form select.form-control {
    color: #bbb1ab;
    /* fix for select on iOs devices */
    background-color: #fff;
}

.dk-form select.form-control option {
    color: #1c334d;
}

@-moz-document url-prefix("") {
    .dk-form select.form-control option {
        color: inherit;
    }
}

.dk-form select.form-control[multiple] option {
    color: inherit;
}

/*---------------------------
  Element Icons
 ----------------------------*/
.dk-icon-quote {
    position: relative;
    display: inline-block;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 20px;
    height: 20px;
}

.dk-icon-quote.dk-icon-quote-white::before, .dk-icon-quote.dk-icon-quote-white::after {
    border-color: #fff;
}

.dk-icon-quote.dk-icon-quote-white:hover::before, .dk-icon-quote.dk-icon-quote-white:hover::after {
    border-color: rgba(255, 255, 255, 0.2);
}

.dk-icon-quote::before, .dk-icon-quote::after {
    content: "";
    position: absolute;
    display: inline-block;
    top: 2px;
    width: 6px;
    height: 16px;
    border-top: 6px solid #131313;
    border-bottom: 6px solid #131313;
    -webkit-transition: border-color .3s ease;
    -o-transition: border-color .3s ease;
    transition: border-color .3s ease;
}

.dk-icon-quote::before {
    left: 2px;
}

.dk-icon-quote::after {
    right: 2px;
}

.dk-icon-quote:hover::before, .dk-icon-quote:hover::after {
    border-color: rgba(255, 255, 255, 0.2);
}

@media screen and (min-width: 600px) { 
    .portrait{
        margin-left:-25px;
    }
}
/*------------------------------------------------------------------

  Blog

 -------------------------------------------------------------------*/


.dk-blog-grid{
    min-height:580px;
}
@media only screen and (max-width: 600px) {
    .dk-blog-grid{
        min-height:unset;
    }
}
.dk-blog-grid .dk-blog-img {
    margin-bottom: 26px;
}

.dropshadow{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.dk-blog-grid .dk-blog-img > a {
    position: relative;
    display: block;
    overflow: hidden;
}

.dk-blog-grid .dk-blog-img > a::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(32, 32, 32, 0.1);
    opacity: 0;
    -webkit-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    transition: opacity .3s ease;
    z-index: 1;
}

.dk-blog-grid .dk-blog-img > a .dk-blog-img-size + img {
    position: absolute;
    top: 0;
    left: 0;
}

.dk-blog-grid .dk-blog-img img {
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover";
    -o-object-fit: cover;
       object-fit: cover;
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    -o-transition: transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}

.dk-blog-grid:hover .dk-blog-img > a::before {
    opacity: 1;
}

.dk-blog-grid:hover .dk-blog-img img {
    -webkit-transform: scale(1.06);
        -ms-transform: scale(1.06);
            transform: scale(1.06);
}

.dk-blog-grid .dk-blog-title {
    margin-bottom: 8px;
}

.dk-blog-grid .dk-blog-title > * {
    margin-bottom: 0;
}

.dk-blog-grid .dk-blog-title a {
    color: #131313;
    text-decoration: none;
}

.dk-blog-grid .dk-blog-title a:hover, .dk-blog-grid .dk-blog-title a:focus {
    color: rgba(255, 255, 255, 0.2);
}

.dk-blog-grid .dk-blog-date,
.dk-blog-grid .dk-blog-tags {
    display: inline-block;
    font-size: .74rem;
    font-weight: 500;
    color: #131313;
    text-transform: uppercase;
    letter-spacing: .03em;
}

.dk-blog-grid .dk-blog-date + .dk-blog-tags::before {
    content: "/";
    margin-right: 4px;
    margin-left: 1px;
}

.dk-blog-grid .dk-blog-tags {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0;
    margin: 0;
}

.dk-blog-grid .dk-blog-tags > li {
    display: inline-block;
    padding: 0;
    margin: 0;
    list-style: none;
}

.dk-blog-grid .dk-blog-tags > li::after {
    content: ",";
}

.dk-blog-grid .dk-blog-tags > li:last-child::after {
    display: none;
}

.dk-blog-grid .dk-blog-tags a {
    color: #131313;
    text-decoration: none;
}

.dk-blog-grid .dk-blog-tags a:hover, .dk-blog-grid .dk-blog-tags a:focus {
    color: rgba(255, 255, 255, 0.2);
}

.dk-blog-grid .dk-blog-text {
    margin: 17px 0 22px;
    color: #5e5e5e;
    letter-spacing: .03em;

}

.dk-blog-post {
    padding: 0 200px;
}

@media screen and (max-width: 992px) {
    .dk-blog-post {
        padding: 0 100px;
    }
}

@media screen and (max-width: 767px) {
    .dk-blog-post {
        padding: 0 40px;
    }
}

@media screen and (max-width: 575px) {
    .dk-blog-post {
        padding: 0 20px;
    }
}

@media screen and (max-width: 440px) {
    .dk-blog-post {
        padding: 0;
    }
}

.dk-blog-post > img {
    max-width: 100%;
    height: auto;
}

.dk-blog-post strong {
    color: #131313;
}

.dk-blog-post u {
    color: #131313;
}

.dk-blog-post .dk-blog-post-overflow {
    position: relative;
    width: auto;
    margin: -20px -200px 0;
}

.dk-blog-post .dk-blog-post-overflow > img {
    max-width: 100%;
    height: auto;
}

@media screen and (max-width: 992px) {
    .dk-blog-post .dk-blog-post-overflow {
        margin: -20px -100px 0;
    }
}

@media screen and (max-width: 767px) {
    .dk-blog-post .dk-blog-post-overflow {
        margin: -20px -40px 0;
    }
}

@media screen and (max-width: 440px) {
    .dk-blog-post .dk-blog-post-overflow {
        margin: -20px -20px 0;
    }
}

.dk-blog-post .dk-blog-post-meta .dk-blog-post-date,
.dk-blog-post .dk-blog-post-meta .dk-blog-post-comments {
    display: inline-block;
    font-size: .74rem;
    font-weight: 500;
    color: #131313;
    text-transform: uppercase;
    letter-spacing: .03em;
}

.dk-blog-post .dk-blog-post-meta .dk-blog-post-comments {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.dk-blog-post .dk-blog-post-meta .dk-blog-post-comments::before {
    content: "/";
    margin-right: 4px;
    margin-left: 1px;
}

.dk-blog-post .dk-blog-post-meta .dk-blog-post-tag {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0;
    margin: 0;
    font-size: .74rem;
    font-weight: 500;
    color: #131313;
    text-transform: uppercase;
    letter-spacing: .03em;
}

.dk-blog-post .dk-blog-post-meta .dk-blog-post-tag::before {
    content: "/";
    margin-right: 4px;
    margin-left: 1px;
}

.dk-blog-post .dk-blog-post-meta .dk-blog-post-tag > li {
    padding: 0;
    margin: 0;
    list-style: none;
}

.dk-blog-post .dk-blog-post-meta .dk-blog-post-tag > li::after {
    content: ",";
}

.dk-blog-post .dk-blog-post-meta .dk-blog-post-tag > li:last-child::after {
    display: none;
}

.dk-blog-post .dk-blog-post-meta .dk-blog-post-tag > li a {
    color: #131313;
}

.dk-blog-post .dk-blog-post-meta .dk-blog-post-tag > li a:hover, .dk-blog-post .dk-blog-post-meta .dk-blog-post-tag > li a:focus {
    color: rgba(255, 255, 255, 0.2);
    text-decoration: none;
}

.dk-comment {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 100%;
}

.dk-comment > .dk-comment {
    margin-top: 44px;
    margin-left: 100px;
}

@media screen and (max-width: 1200px) {
    .dk-comment > .dk-comment {
        margin-left: 80px;
    }
}

@media screen and (max-width: 992px) {
    .dk-comment > .dk-comment {
        margin-left: 60px;
    }
}

@media screen and (max-width: 767px) {
    .dk-comment > .dk-comment {
        margin-left: 40px;
    }
}

@media screen and (max-width: 575px) {
    .dk-comment > .dk-comment {
        margin-left: 20px;
    }
}

.dk-comment + .dk-comment {
    margin-top: 44px;
}

.dk-comment .dk-comment-avatar {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100px;
            flex: 0 0 100px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 100%;
}

@media screen and (max-width: 1200px) {
    .dk-comment .dk-comment-avatar {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 80px;
                flex: 0 0 80px;
        min-width: 80px;
        max-width: 80px;
        height: 80px;
    }
}

@media screen and (max-width: 992px) {
    .dk-comment .dk-comment-avatar {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 60px;
                flex: 0 0 60px;
        min-width: 60px;
        max-width: 60px;
        height: 60px;
    }
}

.dk-comment .dk-comment-avatar + .dk-comment-content {
    margin-left: 30px;
}

.dk-comment .dk-comment-avatar img {
    max-width: 100%;
    height: auto;
}

.dk-comment .dk-comment-content {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.dk-comment .dk-comment-content .dk-comment-meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: -6px;
    margin-right: -18px;
    margin-left: -18px;
    color: #131313;
}

@media screen and (max-width: 992px) {
    .dk-comment .dk-comment-content .dk-comment-meta {
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
}

@media screen and (max-width: 767px) {
    .dk-comment .dk-comment-content .dk-comment-meta {
        min-height: 66px;
    }
}

.dk-comment .dk-comment-content .dk-comment-meta + .dk-comment-text {
    margin-top: 15px;
}

@media screen and (max-width: 767px) {
    .dk-comment .dk-comment-content .dk-comment-meta + .dk-comment-text {
        margin-top: 20px;
        margin-left: -90px;
    }
}

.dk-comment .dk-comment-content .dk-comment-meta .dk-comment-date {
    padding: 0 18px;
    font-size: .74rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .03em;
    white-space: nowrap;
}

.dk-comment .dk-comment-content .dk-comment-meta .dk-comment-name {
    padding: 0 18px;
    font-size: 1.14rem;
    font-weight: 500;
}

.dk-comment .dk-comment-content .dk-comment-meta .dk-comment-name + * {
    margin-left: auto;
}

.dk-comment .dk-comment-content .dk-comment-meta .dk-comment-link {
    padding: 0 18px;
    font-size: .74rem;
    font-weight: 500;
    color: #131313;
    text-transform: uppercase;
    letter-spacing: .03em;
}

.dk-comment .dk-comment-content .dk-comment-meta .dk-comment-link:hover, .dk-comment .dk-comment-content .dk-comment-meta .dk-comment-link:focus {
    color: rgba(255, 255, 255, 0.2);
    text-decoration: none;
}

.dk-comment .dk-comment-content .dk-comment-text p:last-child {
    margin-bottom: 0;
}

/*------------------------------------------------------------------

  Plugins

 -------------------------------------------------------------------*/
/*---------------------------
    Plugin Swiper
 ----------------------------*/
.dk-swiper .swiper-slide {
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.dk-swiper .swiper-slide .dk-swiper-slide {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    z-index: 1;
}

.dk-swiper .swiper-slide .dk-swiper-slide .dk-slide-logo {
    margin-bottom: 42px;
}

.dk-swiper .swiper-slide .dk-swiper-slide .dk-slide-title > * {
    margin-bottom: 30px;
    font-weight: 400;
    letter-spacing: .025em;
}

.dk-swiper .swiper-slide .dk-swiper-slide .dk-slide-text p:last-of-type,
.dk-swiper .swiper-slide .dk-swiper-slide .dk-slide-text p:last-child {
    margin-bottom: 0;
}

.dk-swiper .swiper-slide .dk-swiper-slide .dk-slide-btn {
    margin-top: 47px;
}

.dk-swiper .swiper-slide .dk-swiper-slide img {
    max-width: 100%;
}

.dk-swiper .swiper-pagination {
    bottom: 65px;
}

.dk-swiper .swiper-pagination .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    margin: 0 5px;
    background: none;
    background-color: #202020;
    opacity: .3;
    -webkit-transition: background-color .3s ease, -webkit-transform .3s ease;
    transition: background-color .3s ease, -webkit-transform .3s ease;
    -o-transition: transform .3s ease, background-color .3s ease;
    transition: transform .3s ease, background-color .3s ease;
    transition: transform .3s ease, background-color .3s ease, -webkit-transform .3s ease;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}

.dk-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #202020;
    opacity: 1;
    -webkit-transform: scale(1.6);
        -ms-transform: scale(1.6);
            transform: scale(1.6);
}

.dk-swiper .swiper-button-prev,
.dk-swiper .swiper-button-next {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 60px;
    height: 30px;
    margin-top: -10px;
    font-size: 5rem;
    color: #c8c8c8;
    background: none;
    -webkit-transition: color .2s ease;
    -o-transition: color .2s ease;
    transition: color .2s ease;
    z-index: 2;
}

.dk-swiper .swiper-button-prev > *,
.dk-swiper .swiper-button-next > * {
    font-size: inherit;
    color: inherit;
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    -o-transition: transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;
}

.dk-swiper .swiper-button-prev:hover,
.dk-swiper .swiper-button-next:hover {
    color: rgba(255, 255, 255, 0.2);
}

.dk-swiper .swiper-button-prev:focus,
.dk-swiper .swiper-button-next:focus {
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.dk-swiper .swiper-button-prev {
    left: 60px;
}

.dk-swiper .swiper-button-prev > * {
    margin-top: 3px;
    margin-left: -4px;
}

.dk-swiper .swiper-button-next {
    right: 60px;
}

.dk-swiper .swiper-button-next > * {
    margin-top: -3px;
    margin-right: -4px;
}

.dk-swiper.dk-swiper-white .swiper-pagination .swiper-pagination-bullet {
    background-color: #fff;
    opacity: .3;
}

.dk-swiper.dk-swiper-white .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #fff;
    opacity: 1;
}

.dk-swiper.dk-swiper-white .swiper-button-prev,
.dk-swiper.dk-swiper-white .swiper-button-next {
    color: rgba(255, 255, 255, 0.7);
}

.dk-swiper.dk-swiper-white .swiper-button-prev:hover,
.dk-swiper.dk-swiper-white .swiper-button-next:hover {
    color: #fff;
}

.dk-swiper-main .swiper-pagination {
    bottom: 34px;
}

@media screen and (max-width: 575px) {
    .dk-swiper-main .swiper-button-prev,
    .dk-swiper-main .swiper-button-next {
        display: none;
    }
}

.dk-swiper-slides-auto .dk-swiper-slide {
    padding-bottom: 65px;
}

.dk-swiper-slides-auto .swiper-slide {
    width: 900px;
    max-width: 100%;
}

.dk-swiper-slides-auto .swiper-pagination {
    bottom: -5px;
}

.dk-swiper-fullscreen {
    height: 100vh;
    background-color: #131313;
}

.dk-swiper-fullscreen.dk-swiper-white .swiper-pagination .swiper-pagination-bullet {
    color: rgba(255, 255, 255, 0.7);
    background: none;
}

.dk-swiper-fullscreen.dk-swiper-white .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    color: #fff;
    background: none;
}

.dk-swiper-fullscreen .swiper-slide {
    opacity: .9;
    -webkit-transition: opacity .6s ease, -webkit-transform .6s ease;
    transition: opacity .6s ease, -webkit-transform .6s ease;
    -o-transition: transform .6s ease, opacity .6s ease;
    transition: transform .6s ease, opacity .6s ease;
    transition: transform .6s ease, opacity .6s ease, -webkit-transform .6s ease;
    -webkit-transition-delay: .3s;
         -o-transition-delay: .3s;
            transition-delay: .3s;
    -webkit-transform: scale(0.94);
        -ms-transform: scale(0.94);
            transform: scale(0.94);
}

.dk-swiper-fullscreen .swiper-slide.swiper-slide-active {
    opacity: 1;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}

.dk-swiper-fullscreen .swiper-slide .dk-swiper-slide {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    height: 100vh;
    padding: 0 100px;
    overflow: hidden;
}

@media screen and (max-width: 767px) {
    .dk-swiper-fullscreen .swiper-slide .dk-swiper-slide {
        padding: 0 50px;
    }
}

@media screen and (max-width: 575px) {
    .dk-swiper-fullscreen .swiper-slide .dk-swiper-slide {
        padding: 0 40px 0 40px;
    }
}

.dk-swiper-fullscreen .swiper-pagination {
    right: 50px;
    bottom: auto;
}

@media screen and (max-width: 575px) {
    .dk-swiper-fullscreen .swiper-pagination {
        right: 25px;
    }
}

@media screen and (max-width: 440px) {
    .dk-swiper-fullscreen .swiper-pagination {
        right: 20px;
    }
}

.dk-swiper-fullscreen .swiper-pagination .swiper-pagination-bullet {
    width: auto;
    height: auto;
    margin: 28px 0;
    font-weight: 500;
    color: #9f9f9f;
    background: none;
    border-radius: 0;
    opacity: 1;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
}

.dk-swiper-fullscreen .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    color: #131313;
    background: none;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
}

.dk-swiper-news .swiper-pagination,
.dk-swiper-news .swiper-pagination.swiper-pagination-bullets-dynamic {
    bottom: -5px;
}

.dk-swiper-news .dk-swiper-slide {
    padding-bottom: 58px;
}

.dk-swiper-reviews .swiper-pagination {
    bottom: -5px;
}

.dk-swiper-reviews .dk-swiper-slide {
    padding-bottom: 80px;
}

.dk-swiper-reviews .swiper-button-prev,
.dk-swiper-reviews .swiper-button-next {
    width: 30px;
    height: 40px;
    margin-top: -30px;
    overflow: hidden;
}

.dk-swiper-reviews .swiper-button-prev:hover,
.dk-swiper-reviews .swiper-button-next:hover {
    color: #131313;
}

.dk-swiper-reviews .swiper-button-next {
    right: 45px;
}

.dk-swiper-reviews .swiper-button-prev {
    left: 45px;
}

@media screen and (max-width: 575px) {
    .dk-swiper-reviews .swiper-button-next,
    .dk-swiper-reviews .swiper-button-prev {
        display: none;
    }
}

.dk-swiper-brand {
    padding: 60px 0;
}

.dk-swiper-brand .swiper-wrapper {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.dk-swiper-work .swiper-slide {
    height: auto;
}

.dk-swiper-work .dk-swiper-slide {
    height: 100%;
}

.dk-swiper-work .dk-swiper-slide .dk-swiper-slide-img {
    width: 100%;
    height: 100%;
}

.dk-swiper-work .dk-swiper-slide .dk-swiper-slide-img img {
    width: 100%;
    height: 100%;
    max-height: 670px;
    font-family: "object-fit: cover";
    -o-object-fit: cover;
       object-fit: cover;
}

.dk-swiper-work .swiper-button-prev,
.dk-swiper-work .swiper-button-next {
    font-size: 3.4rem;
    color: #a8a8a8;
}

.dk-swiper-work .swiper-button-prev:hover,
.dk-swiper-work .swiper-button-next:hover {
    color: #131313;
}

@media screen and (max-width: 767px) {
    .dk-swiper-work .swiper-button-prev {
        left: 40px;
    }
}

@media screen and (max-width: 575px) {
    .dk-swiper-work .swiper-button-prev {
        left: 20px;
    }
}

@media screen and (max-width: 440px) {
    .dk-swiper-work .swiper-button-prev {
        left: 0;
    }
}

@media screen and (max-width: 767px) {
    .dk-swiper-work .swiper-button-next {
        right: 40px;
    }
}

@media screen and (max-width: 575px) {
    .dk-swiper-work .swiper-button-next {
        right: 20px;
    }
}

@media screen and (max-width: 440px) {
    .dk-swiper-work .swiper-button-next {
        right: 0;
    }
}

/*---------------------------
  Plugin Isotope
 ----------------------------*/
.dk-isotope-filter {
    padding: 32px 0 37px;
    margin: 0 -13px;
}

.dk-isotope-filter.dk-isotope-filter-white > li {
    color: #c8c8c8;
}

.dk-isotope-filter.dk-isotope-filter-white > li:hover {
    color: #fff;
}

.dk-isotope-filter.dk-isotope-filter-white > li.active {
    color: rgba(255, 255, 255, 0.2);
}

.dk-isotope-filter > li {
    display: inline-block;
    padding: 10px 0 0;
    margin: 0 13px 0 13px;
    font-size: .82rem;
    font-weight: 500;
    color: #858585;
    text-transform: uppercase;
    list-style: none;
    cursor: pointer;
    -webkit-transition: color .3s ease;
    -o-transition: color .3s ease;
    transition: color .3s ease;
}

.dk-isotope-filter > li:hover, .dk-isotope-filter > li.active {
    color: #131313;
}
